export default {
  nameNews: 'Tên tin tức',
  showNews: 'Hiển thị tất cả tin tức',
  createNews: 'Tạo tin tức',
  choice: 'Chọn',
  titleModel: 'Bộ lọc nâng cao',
  placeholderInInf: 'Nhập thông tin',
  titleButModel: 'Lọc ngay',
  listNews: 'Danh sách tin tức',
  dayPost: 'Ngày đăng',
  creator: 'Người tạo',
  action: 'Hành động',
  viewer: 'Lượt xem',
  time: 'Thời gian',
  from: 'Từ',
  to: 'Đến',
  deleteFilter: 'Xóa bộ lọc',
  titleDelete: 'Xóa tin tức',
  desDelete: 'Bạn có chắc muốn xóa tin tức không',
  advancedFilter: 'Bộ lọc nâng cao',
  filterNow: 'Lọc ngay',
};
