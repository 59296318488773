import request from 'services/request';

export const getListCatalogContestApi = async (params) =>
  request({
    url: '/user/study-test/list',
    method: 'GET',
    params,
  });
export const getQuestionContestApi = async (id) =>
  request({
    url: `/user/study-test/category?category_id=${id}`,
    method: 'GET',
  });
export const getNotificationQuestionApi = async (data) =>
  request({
    url: '/user/study-test/notification-result',
    method: 'PUT',
    data,
  });
export const getNotificationAnswerApi = async (data) =>
  request({
    url: '/user/study-test/notification-answer',
    method: 'POST',
    data,
  });
