export default {
  timeCountDownt: 'Thời gian đếm ngược',
  numberQues: 'Số câu hỏi trong đề',
  numberQuesEz: 'Số lượng câu hỏi Dễ',
  numberQuesMedium: 'Số lượng câu hỏi Trung bình',
  numberQuesDiff: 'Số lượng câu hỏi Khó',
  saveAndSetting: 'LƯU CÀI ĐẶT',
  setOfRule: 'Chọn nội quy',
  setOfQuestion: 'Chọn bộ câu hỏi',
  choose: 'Chọn',
};
