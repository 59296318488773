export default {
  uploadBaner: 'Upload Banner',
  nameCompetition: 'Tên Cuộc Thi',
  timeCompetirion: 'Thời Gian Cuộc Thi',
  chooseSetOfQuestions: 'Chọn Bộ Câu Hỏi',
  contestInformation: 'Thông Tin Cuộc Thi',
  placeholderNameCompetition: 'Nhập tên cuộc thi',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày bắt kết thúc',
  deleteAll: 'Xóa tất cả',
  delete: 'Xóa',
  content: 'Nội dung',
  chooseRule: 'Chọn Nội Quy Cuộc Thi',
  numberOfCandidatesPassed: 'Số lượng thí sinh vượt qua',
  numberQues: 'Số câu hỏi trong vòng',
  numberQuesEz: 'Số lượng câu hỏi Dễ',
  numberQuesMedium: 'Số lượng câu hỏi Trung bình',
  numberQuesDiff: 'Số lượng câu hỏi Khó',
  description: 'Tối đa 1 ảnh, định dạng jpg, png',
  choose: 'Chọn',
  donors: 'Nhà Tài Trợ',
  chooseDonors: 'Chọn nhà tài trợ ',
  chooseRank: 'Chọn thứ hạng',
  timeCountDownt: 'Thời gian đếm ngược',
  addRound: 'Thêm Vòng Thi',
  website: 'Website',
  nameDonors: 'Tên',
  inputName: 'Nhập tên',
  inputWeb: 'Nhập website',
  moreFromSystem: 'THÊM  TỪ HỆ THỐNG',
  addNew: 'TẠO MỚI ',
  moreTestRound: 'THÊM VÒNG THI',
  showHome: 'Hiển Thị Trên Trang Chủ',
  createTest: 'TẠO CUỘC THI',
  gold: 'VÀNG',
  silver: 'BẠC',
  brass: 'ĐỒNG',
  diamond: 'KIM CƯƠNG',
  time: 'THỜI GIAN',
  round: 'VÒNG',
  roundTwo: 'VÒNG 2',
  passConditions: 'ĐIỀU KIỆN VƯỢT QUA',
  donor: 'Nhà tài trợ ',
  new: 'Mới',
  question: 'CÂU HỎI',
  uploadLogo: 'Logo nhà tài trợ',
  save: 'CẬP NHẬP',
};
