import request from 'services/request';

export const SettingApi = async (params) =>
  request({
    url: `test-setting`,
    method: 'GET',
    params,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const SaveSetting = async (data) =>
  request({
    url: `test-setting`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
