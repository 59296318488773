export default {
  home: 'Trang chủ',
  studyForTest: 'Luyện thi',
  listQuestions: 'Tất cả câu hỏi',
  competition: 'Cuộc thi',
  donors: 'Nhà tài trợ',
  policy: 'Chính sách',
  aboutUs: 'Về chúng tôi',
  contact: 'Liên hệ',
  login: 'Đăng nhập',
  new: 'Tin tức',
  introduce: 'Giới thiệu',
 
};
