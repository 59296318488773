import { ROUTES_ADMIN } from 'routes/constants';
import iconDash from 'assets/icons/dashboard.svg';
import iconSetting from 'assets/icons/settings.svg';
import iconTag from 'assets/icons/tag.svg';
import iconProfile from 'assets/icons/profile.svg';
import iconNews from 'assets/icons/icon-news.svg';

export const data = [
  {
    id: 'P1',
    title: 'dashboard',
    link: ROUTES_ADMIN.OVERVIEW_PAGE,
    icon: iconDash,
  },
  {
    id: 'P2',
    title: 'managementQuestion',
    icon: iconDash,
    menus: [
      {
        id: 'P2-C1',
        link: ROUTES_ADMIN.LIST_QUESTIONS,
        title: 'listQuestions',
        permission: 'listQuestions',
        keyword: 'questions',
        icon: '',
      },
      {
        id: 'P2-C2',
        link: ROUTES_ADMIN.CATEGORIES_QUESTION,
        title: 'categoriesQuestion',
        permission: 'categoriesQuestion',
        keyword: 'questions',
        icon: '',
      },
      {
        id: 'P2-C3',
        link: ROUTES_ADMIN.SETOF_QUESTION,
        title: 'setOfQuestion',
        permission: 'setOfQuestion',
        keyword: 'questions',
        icon: '',
      },
    ],
  },
  {
    id: 'P3',
    title: 'managementCompetition',
    icon: iconTag,
    menus: [
      {
        id: 'P3-C1',
        link: ROUTES_ADMIN.LIST_COMPETITIONS,
        title: 'listCompetitions',
        permission: 'listCompetitions',
        keyword: 'questions',
        icon: '',
      },
    ],
  },
  {
    id: 'P4',
    title: 'managementUser',
    icon: iconProfile,
    menus: [
      {
        id: 'P4-C1',
        link: ROUTES_ADMIN.USER_MANAGEMENT,
        title: 'allExaminee',
        permission: 'allExaminee',
        keyword: 'admin',
        icon: '',
      },
    ],
  },
  {
    id: 'P7',
    title: 'managementNews',
    icon: iconNews,
    menus: [
      {
        id: 'P7-C1',
        link: ROUTES_ADMIN.LIST_NEWS,
        title: 'listNews',
        permission: 'listNews',
        keyword: 'admin',
        icon: '',
      },
    ],
  },
  {
    id: 'P6',
    title: 'settings',
    icon: iconSetting,
    menus: [
      {
        id: 'P6-C1',
        link: ROUTES_ADMIN.PRACTICE,
        title: 'practice',
        permission: 'policy',
        keyword: 'policy',
        icon: '',
      },
      {
        id: 'P6-C2',
        link: ROUTES_ADMIN.RULES,
        title: 'rules',
        permission: 'policy',
        keyword: 'policy',
        icon: '',
      },
      {
        id: 'P6-C3',
        link: ROUTES_ADMIN.INTRODUCE_PAGE,
        title: 'introduce',
        permission: 'introduce',
        keyword: 'introduce',
        icon: '',
      },
      {
        id: 'P6-C4',
        link: ROUTES_ADMIN.PARTNER,
        title: 'listPartner',
        permission: 'listPartner',
        keyword: 'partner',
        icon: '',
      },
      {
        id: 'P6-C5',
        link: ROUTES_ADMIN.SETTING_EXAM,
        title: 'settingExam',
        permission: 'settingExam',
        keyword: 'settingExam',
        icon: '',
      },
      {
        id: 'P6-C6',
        link: ROUTES_ADMIN.MANAGEMENT_HEADER_AND_FOOTER,
        title: 'headerAndFooter',
        permission: 'headerAndFooter',
        keyword: 'headerAndFooter',
        icon: '',
      },
    ],
  },
];
