export default {
  titleCof: 'Xóa câu hỏi',
  titleModel: 'Bộ lọc nâng cao',
  descCof: 'Bạn có chắc muốn xóa câu hỏi không?',
  titleImg: 'Hình Ảnh',
  titleTextQues: 'Tên câu hỏi',
  titleListQues: 'Bộ câu hỏi',
  titleCategory: 'Danh mục',
  titleCreator: 'Người tạo',
  titleLvDifficul: 'Độ khó',
  titleAction: 'Hành động',
  titleButModel: 'ÁP DỤNG',
  titleTable: 'Danh sách câu hỏi',
  dataName: 'Thêm câu hỏi trắc nghiệm',
  dataNameImg: 'Thêm câu hỏi trắc nghiệm đáp án có hình ảnh',
  levelEasy: 'Dễ',
  levelMedium: 'Trung bình',
  levelDifficult: 'Khó',
  labelCategory: 'Danh mục',
  placeholderField: 'Chọn',
  placeholderInInf: 'Nhập thông tin',
  labelListQues: 'Bộ câu hỏi',
  labelCreator: 'Người tạo',
  labelLvDifficul: 'Độ khó',
  easy: 'Dễ',
  medium: 'Trung bình',
  hard: 'Khó',
  nameQuestion: 'Khi nào người tập sự hành nghề đấu giá phải nộp kết quả tập sự?',
  person: 'Nguyễn Văn A',
  department: 'Khám phá',
  category: 'Pháp luật',
};
