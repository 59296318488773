import layout from 'components/Layout/locales/vi';
import layoutUser from 'components/LayoutUser/locales/vi';
import categoriesQuestion from 'containers/AdminPage/CategoriesQuestionPage/locales/vi';
import managementAdmin from 'containers/AdminPage/ManagementAdminPage/locales/vi';
import managementCandidates from 'containers/AdminPage/ManagementCandidatesPage/locales/vi';
import managementCompetition from 'containers/AdminPage/ManagementCompetitionPage/locales/vi';
import groupRadioButton from 'components/Form/GroupRadioButton/locales/vi';
import addCompetition from 'containers/AdminPage/AddCompetitionPage/locales/vi';
import managementPartner from 'containers/AdminPage/ManagementPartnerPage/locales/vi';
import managementPermission from 'containers/AdminPage/ManagementPermissionPage/locales/vi';
import managementQuestion from 'containers/AdminPage/ManagementQuestionPage/locales/vi';
import notification from 'containers/AdminPage/NotificationPage/locales/vi';
import overview from 'containers/AdminPage/OverviewPage/locales/vi';
import policyPage from 'containers/AdminPage/PolicyPage/locales/vi';
import profilePage from 'containers/AdminPage/ProfilePage/locales/vi';
import loginPage from 'containers/OtherPage/LoginPage/locales/vi';
import forgotPasswordPage from 'containers/OtherPage/ForgotPasswordPage/locales/vi';
import successForgotPasswordPage from 'containers/OtherPage/SuccessForgotPasswordPage/locales/vi';
import importQuestionPage from 'containers/AdminPage/ImportQuestionPage/locales/vi';
import tablePage from 'components/commons/Table/locales/vi';
import createQuestionPage from 'containers/AdminPage/CreateQuestionPage/locales/vi';
import departmentQuestionPageDetail from 'containers/AdminPage/DepartmentQuestionPageDetail/locales/vi';
import CategoriesQuestionPageDetail from 'containers/AdminPage/CategoriesQuestionPageDetail/locales/vi';
import managementCategoryQuestionPage from 'containers/AdminPage/ManagementCategoryQuestionPage/locales/vi';
import editQuestionPage from 'containers/AdminPage/EditQuestionPage/locales/vi';
import settingExamPage from 'containers/AdminPage/SettingExamPage/locales/vi';
import resetPasswordPage from 'containers/OtherPage/ResetPasswordPage/locales/vi';
import setOfQuestionPage from 'containers/AdminPage/SetOfQuestionPage/locales/vi';
import forgotPassModal from 'containers/OtherPage/ForgotPassModal/locales/vi';
import modalConfirm from 'components/commons/ModalConfirm/locales/vi';
import modal from 'components/commons/Modal/locales/vi';
import rulesPage from 'containers/AdminPage/RulesPage/locales/vi';
import editCompetition from 'containers/AdminPage/EditCompetitionPage/locales/vi';
import managementCompetitionDetail from 'containers/AdminPage/ManagementCompetitionPageDetails/locales/vi';
import practicePage from 'containers/AdminPage/PracticePage/locales/vi';
import profilePageUser from 'containers/UserPage/ProfilePage/locales/vi';
import ContestResults from 'containers/UserPage/ContesResultsPage/locales/vi';
import HomeUser from 'containers/UserPage/HomePageUser/locales/vi';
import homeUserPage from 'containers/UserPage/HomeUser/locales/vi';
import donors from 'components/Users/Donors/locales/vi';
import footer from 'components/Users/Footer/locales/vi';
import questionContest from 'components/Users/QuestionContest/locales/vi';
import contestDetail from 'containers/UserPage/ContestDetailPage/locales/vi';
import chooseField from 'containers/UserPage/ChooseFieldsPage/locales/vi';
import slideDonors from 'components/Users/SlideDonors/locales/vi';
import listNews from 'containers/AdminPage/ManagementListNewsPage/locales/vi';
import addListNews from 'containers/AdminPage/CreateListNewsPage/locales/vi';
import resultPage from 'containers/UserPage/ResultPage/locales/vi';
import contestEntered from 'containers/UserPage/ProfilePage/components/ContestEntered/locales/vi';
import editNews from 'containers/AdminPage/EditNewsPage/locales/vi';
import listNewsDetail from 'containers/AdminPage/ListNewsPageDetail/locales/vi';
import listNewsUserPage from 'containers/UserPage/ListNewsUserPage/locales/vi';
import introducePage from 'containers/AdminPage/IntroducePage/locales/vi';
import donorPage from 'containers/UserPage/DonorPage/locales/vi';
import detailNewsUserPage from 'containers/UserPage/DetailNewsUserPage/locales/vi';
import managementHeaderAndFooter from 'containers/AdminPage/ManagementHeaderAndFooter/locales/vi';
import trueDetailExam from 'containers/UserPage/TrueDetailExamPage/locales/vi';
import listDetailManaUser from 'containers/AdminPage/DetailManagementCandidatesPage/locales/vi';
import contactPage from 'containers/UserPage/ContactPage/locales/vi';
import introducePageUser from 'containers/UserPage/IntroducePage/locales/vi';
import auditions from 'components/Users/Auditions/locales/vi';
import validation from './validation/vi';

export default {
  layout,
  layoutUser,
  categoriesQuestion,
  managementAdmin,
  managementCandidates,
  managementCompetition,
  managementCompetitionDetail,
  addCompetition,
  editCompetition,
  managementPartner,
  managementPermission,
  managementQuestion,
  notification,
  overview,
  policyPage,
  profilePage,
  loginPage,
  forgotPasswordPage,
  successForgotPasswordPage,
  importQuestionPage,
  tablePage,
  createQuestionPage,
  departmentQuestionPageDetail,
  CategoriesQuestionPageDetail,
  managementCategoryQuestionPage,
  editQuestionPage,
  settingExamPage,
  resetPasswordPage,
  modalConfirm,
  setOfQuestionPage,
  forgotPassModal,
  validation,
  rulesPage,
  practicePage,
  profilePageUser,
  footer,
  groupRadioButton,
  ContestResults,
  homeUserPage,
  questionContest,
  contestDetail,
  chooseField,
  slideDonors,
  donors,
  listNews,
  modal,
  resultPage,
  addListNews,
  contestEntered,
  editNews,
  listNewsDetail,
  listNewsUserPage,
  HomeUser,
  donorPage,
  detailNewsUserPage,
  introducePage,
  managementHeaderAndFooter,
  trueDetailExam,
  listDetailManaUser,
  contactPage,
  introducePageUser,
  auditions,
};
