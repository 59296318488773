export default {
  title:
    'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
  titleUnder:
    'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like .',
  introduce: 'Giới thiệu',
  titleTeam: 'Đội ngũ chuyên gia hàng đầu',
  desTeam:
    'It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
  titleOra: 'Tổ chức cuộc thi chuyên nghiệp trên sự cố vấn của nhiều chuyên gia',
};
