import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SettingApi, SaveSetting } from 'services/apis/admin/setting';

export const getSetting = createAsyncThunk(
  'setting/GET_SETTING',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SettingApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const saveSetting = createAsyncThunk(
  'setting/SAVE_SETTING',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SaveSetting(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  setting: {},
  saveListSetting: {},
  paginate: {},
  details: {},
  totalAmount: {},
  loading: false,
  error: {},
};

const settingSlice = createSlice({
  name: 'admin',
  initialState,

  extraReducers: {
    [getSetting.pending]: (state) => {
      state.loading = true;
    },
    [getSetting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getSetting.fulfilled]: (state, action) => {
      state.setting = action?.payload?.data;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
    [saveSetting.pending]: (state) => {
      state.loading = true;
    },
    [saveSetting.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [saveSetting.fulfilled]: (state, action) => {
      state.saveListSetting = action?.payload?.data;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});
const { reducer: settingReduce } = settingSlice;
export default settingReduce;
