export default {
  statusTitle: ' Bạn đã upload thành công 5 câu hỏi, 1 câu lỗi.',
  importContent: ' IMPORT CÂU HỎI',
  process: ' Importing',
  cancel: ' HỦY',
  question: ' CÂU HỎI',
  success: ' THÀNH CÔNG',
  error: 'LỖI',
  easy: 'Dễ',
  medium: 'Trung bình',
  hard: 'Khó',
  null: 'Không đúng định dạng',
  nameQuestion: 'Tên câu hỏi',
  status: 'Trạng thái',
  setQuestion: 'Bộ câu hỏi',
  field: 'Lĩnh vực',
  kindOfQuestion: 'Loại câu hỏi',
  errorOne: 'Lỗi',
  successOne: 'Thành công',
};
