import { useState } from 'react';
import useTranslation from 'hooks/useTranslation.js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'routes/constants';

import Input from 'components/Form/InputField';
import Button from 'components/commons/Button';
import { resetPassApi } from 'services/apis/other/auth';
import { Text } from 'components/commons/Typo';
import { PoweroffOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import iconEmail from '../Image/email.svg';
import logo from '../Image/logo.svg';

import { Schema } from './constants';
import * as S from './styled';

function ForgotPassword() {
  const { t } = useTranslation('forgotPasswordPage');
  const history = useHistory();
  const [loadings, setLoadings] = useState(false);
  const emailValidation = useForm({ resolver: yupResolver(Schema), mode: 'onChange' });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Schema), mode: 'onChange' });

  const handleValidSubmit = async (data) => {
    setLoadings(true);
    const res = await resetPassApi(data);
    if (res.status === 200) {
      history.push(ROUTES.FORGOT_PASSWORD_MODAL, {
        update: data.email,
      });
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <img src={logo} alt="logo" />
      </S.Header>
      <S.Form onSubmit={emailValidation?.handleSubmit(handleValidSubmit)}>
        <S.Title>{t('titleForgotPassword')}</S.Title>
        <S.Label>{t('inputEmail')}</S.Label>
        <Input
          $height="52px"
          placeholder={t('inputEmail')}
          name="email"
          icon={iconEmail}
          register={emailValidation?.register}
          error={emailValidation.formState?.errors?.email?.message}
        />
        <S.Label>{t('inputEmailAgain')}</S.Label>
        <Input
          $height="52px"
          placeholder={t('inputEmailAgain')}
          icon={iconEmail}
          name="confirmEmail"
          register={emailValidation?.register}
          error={emailValidation.formState?.errors?.confirmEmail?.message}
        />
        <Button type="submit" disabled={loadings} width="100%">
          {t('passwordRetrieval')}
        </Button>
      </S.Form>
    </S.Wrapper>
  );
}

export default ForgotPassword;
