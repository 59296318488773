import request from 'services/request';

export const getListOverviewApi = async (params) =>
  request({
    url: 'admin/dashboard',
    method: 'GET',
    params,
  });

export const getListTopStaffApi = async (params) =>
  request({
    url: 'admin/dashboard/top/users',
    method: 'GET',
    params,
  });

export const getListTopStaffsApi = async (params) =>
  request({
    url: 'admin/dashboard/top/users?paginate=true',
    method: 'GET',
    params,
  });

export const getChartDataApi = async (params) =>
  request({
    url: 'admin/dashboard/statistics',
    method: 'GET',
    params,
  });

export const getAdminFooterApi = async (params) =>
  request({
    url: '/home-setting/detail',
    method: 'GET',
    params,
  });
export const updateAdminFooterApi = async (data) =>
  request({
    url: `/home-setting/update`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
