export default {
  moreQuestionCategories: 'Thêm lĩnh vực câu hỏi',
  addListQuestion: 'Thêm lĩnh vực',
  enterListQuestion: 'Nhập tên lĩnh vực',
  add: 'Thêm',
  listQuestion: 'Danh Sách Lĩnh Vực',
  nameList: 'Tên lĩnh vực',
  questionNumber: 'Số câu hỏi',
  action: 'Hành động',
  titleShowAll: 'Hiển thị tất cả lĩnh vực ',
  editListQuestion: 'Sửa lĩnh vực',
  edit: 'Sửa',
  deleteListQuestion: 'Xóa lĩnh vực',
  desDelete: 'Bạn có chắc muốn xóa lĩnh vực không',
  desDeleteConfirm: 'Lĩnh vực đã có câu hỏi, không thể xóa',
  understood: 'Đã hiểu',
  wisdom: 'Trí tuệ',
};
