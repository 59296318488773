export default {
  useName: 'Tài khoản đăng nhập',
  plhuseName: 'Nhập tài khoản',
  pass: 'Mật khẩu',
  plhPass: 'Nhập mật khẩu ',
  remember: 'Ghi nhớ đăng nhập',
  forgotPass: 'Quên mật khẩu ?',
  submit: 'ĐĂNG NHẬP',
  errorShow: 'Tài khoản hoặc mật khẩu không đúng, vui lòng thử lại',
};
