export default {
  rank: 'Xếp hạng',
  nameCandidates: 'Tên thí sinh',
  email: 'Email',
  numberSDT: 'Số điện thoại',
  score: 'Số điểm',
  TimeToDo: 'Thời gian làm bài',
  yourResults: 'KẾT QUẢ CỦA BẠN',
  contestResults: 'KẾT QUẢ CUỘC THI',
  round1: 'VÒNG 1',
  round2: 'VÒNG 2',
  round3: 'VÒNG 3',
  timeRound: 'Từ 23/5 đến 30/5',
  happened: 'ĐÃ HOÀN THÀNH',
  happenning: 'ĐANG DIỄN RA',
  neverHappened: 'CHƯA DIỄN RA',
  textTile: 'Cuộc thi "Tìm hiểu Pháp luật trực tuyến" 2021',
  to: 'đến',
};
