import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import useTranslation from 'hooks/useTranslation.js';

import { login } from 'store/other/authSlice';
import { ROUTES } from 'routes/constants';

import Input from 'components/Form/InputField';
import Button from 'components/commons/Button';

import iconUser from '../Image/user.svg';
import iconLock from '../Image/lock.svg';
import logo from '../Image/logo.svg';
import eye from '../Image/eye.svg';
import iconShow from '../Image/iconShow.svg';

import Schema from './constants';
import * as S from './styled';

function LoginPage() {
  const { t } = useTranslation('loginPage');
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [savePass, setSavePass] = useState(false);
  const [token, setToken] = useState(null);
  const errLogin = useSelector((state) => state.authReducer.error);
  const loading = useSelector((state) => state.authReducer.loading);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(Schema) });

  useEffect(() => {
    if (localStorage.getItem('email')) {
      setValue('email', localStorage.getItem('email'));
      setValue('password', localStorage.getItem('password'));
      setSavePass(true);
    }
  }, []);
  const handleForgot = () => {
    history.push(ROUTES.FORGOT_PASSWORD);
  };
  const onSubmit = async (data) => {
    if (token) {
      data.device_token = token;
    }
    dispatch(login({ ...data, isRemember: savePass }));
    if (savePass) {
      if (errLogin?.length === 0) {
        localStorage.setItem('email', data.email);
        localStorage.setItem('password', data.password);
      } else {
        localStorage.clear();
      }
    } else {
      localStorage.clear();
    }
  };
  return (
    <S.Wrapper>
      <S.Header>
        <img src={logo} alt="logo" />
      </S.Header>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <S.Title>{t('submit')}</S.Title>
        <S.Label>{t('useName')}</S.Label>
        <Input
          $height="52px"
          placeholder={t('plhuseName')}
          name="email"
          icon={iconUser}
          register={register}
          error={errors?.email?.message}
        />
        <S.Label>{t('pass')}</S.Label>
        <Input
          $height="48px"
          icon={iconLock}
          iconPass={show ? iconShow : eye}
          placeholder={t('plhPass')}
          name="password"
          type={show ? 'text' : 'password'}
          set_show={setShow}
          show={show}
          error={errors?.password?.message}
          register={register}
        />
        <S.ForgotPass>
          <S.BoxRadio>
            <S.RadioCustom checked={savePass} setChecked={setSavePass} />
            <a>{t('remember')}</a>
          </S.BoxRadio>

          <button type="button" onClick={() => handleForgot()}>
            {t('forgotPass')}
          </button>
        </S.ForgotPass>
        {errLogin?.length && !loading && (
          <S.ErrorMessage>
            <span>{t('errorShow')}</span>
          </S.ErrorMessage>
        )}
        <Button className="btn-login" type="submit" width="100%">
          {t('submit')}
        </Button>
      </S.Form>
    </S.Wrapper>
  );
}

export default LoginPage;
