export default {
  ques: 'CÂU',
  exit: 'THOÁT',
  prev: 'QUAY LẠI',
  complete: 'HOÀN THÀNH',
  next: 'TIẾP THEO',
  youComplete: 'BẠN ĐÃ HOÀN THÀNH BÀI THI',
  notifi: 'BẠN ĐÃ HOÀN THÀNH BÀI THI',
  timeUp: 'ĐÃ HẾT THỜI GIAN. KHÔNG THỂ TIẾP TỤC LÀM BÀI THI',
  notifiTimeUp:'Bạn sẽ nhận được email thông báo nếu vượt qua vòng này',
  understood: 'ĐÃ HIỂU',
  timeEnd: 'Hết Thời Gian Làm Bài',
  titleComplete: 'Hoàn Thành Bài Thi',
};
