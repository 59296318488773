export default {
  contact: 'Liên hệ',
  contactUs: 'Liên hệ với chúng tôi',
  title: 'Xin vui lòng liên hệ với chúng tôi bằng cách điền thông tin vào mẫu form bên dưới',
  lastName: 'Họ và tên',
  lastNameTwo: 'Họ và tên *',
  phone: 'Số điện thoại',
  phoneTwo: 'Số điện thoại *',
  emailOf: 'Email của bạn',
  emailOfTwo: 'Email *',
  contentMesage: 'Nội dung tin nhắn *',
  importContentMesage: 'Nhấp nội dung tin nhắn',
  sendMessage: 'GỬI TIN NHẮN',
  contactForm: 'Thông tin liên hệ',
  addressTitle:
    ' Tầng 6 Tòa nhà Thành Lợi, 249 Nguyễn Văn Linh, P. Vĩnh Trung, Q. Thanh Khê, TP. Đà Nẵng',
  hotLine: 'Hotline: 02363 822 678',
};
