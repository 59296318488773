import {
  getListContestApi,
  getListCompetitionHomeApi,
  getListCompetitionHomeDetailApi,
  getListCompetitionExamApi,
  getListHomeApi,
  getListQuesAnswersApi,
  getRulestApi,
  getAuditionsApi,
  getListAuditonsApi,
} from 'services/apis/user/homecontest';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListContest = createAsyncThunk(
  'catalog/GET_CONTEST_LIST_HOME',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListContestApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getListHome = createAsyncThunk(
  'catalog/GET_CONTEST_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListHomeApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getListCompetitionHome = createAsyncThunk(
  'catalog/GET_COMPETITION_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCompetitionHomeApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getListQuesAnswers = createAsyncThunk(
  'catalog/GET_QUES_ANSWER',
  async ({ id, idR }, { rejectWithValue }) => {
    try {
      const response = await getListQuesAnswersApi(id, idR);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getListCompetitionDetailHome = createAsyncThunk(
  'catalog/GET_COMPETITION_LIST_DETAIL',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getListCompetitionHomeDetailApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getListCompetitionExam = createAsyncThunk(
  'catalog/GET_COMPETITION_LIST_EXAM',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getListCompetitionExamApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getRules = createAsyncThunk(
  'catalog/GET_RULES_CONTEST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getRulestApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getAuditions = createAsyncThunk(
  'catalog/GET_AUDITIONS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAuditionsApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getAuditionsQuestion = createAsyncThunk(
  'catalog/GET_AUDITIONS_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListAuditonsApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listHomeContest: [],
  listCompetition: [],
  listHome: [],
  listRules: [],
  listAuditions: [],
  listResultAuditions: [],
  listCompetitionDetailHome: [],
  listCompetitionResultExam: [],
  listQuesAnswer: [],
  timeCountDownt: [],
  loading: false,
  error: {},
  reFresh: false,
};

const userContestSlice = createSlice({
  name: 'userCatalogContest',
  initialState,
  reducers: {
    actionRefresh: (state) => {
      state.reFresh = true
    }
  },
  extraReducers: {
    [getListContest.pending]: (state) => {
      state.loading = true;
    },
    [getListContest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getListContest.fulfilled]: (state, action) => {
      state.listHomeContest = action?.payload?.data;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
    [getListHome.pending]: (state) => {
      state.loading = true;
    },
    [getListHome.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getListHome.fulfilled]: (state, action) => {
      state.listHome = action?.payload?.data;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
    [getListCompetitionHome.pending]: (state) => {
      state.loading = true;
    },
    [getListCompetitionHome.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCompetitionHome.fulfilled]: (state, action) => {
      state.listCompetition = action?.payload?.data;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
    [getListQuesAnswers.pending]: (state) => {
      state.loading = true;
    },
    [getListQuesAnswers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getListQuesAnswers.fulfilled]: (state, action) => {
      state.listQuesAnswer = action?.payload?.data;
      state.timeCountDownt = action?.payload?.time_down;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
    [getListCompetitionDetailHome.pending]: (state) => {
      state.loading = true;
    },
    [getListCompetitionDetailHome.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getListCompetitionDetailHome.fulfilled]: (state, action) => {
      state.listCompetitionDetailHome = action?.payload?.data;
      state.paginate = action.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
    [getListCompetitionExam.pending]: (state) => {
      state.loading = true;
    },
    [getListCompetitionExam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCompetitionExam.fulfilled]: (state, action) => {
      state.listCompetitionResultExam = action?.payload?.data;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
    [getRules.pending]: (state) => {
      state.loading = true;
    },
    [getRules.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getRules.fulfilled]: (state, action) => {
      state.listRules = action?.payload?.data;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
    [getAuditions.pending]: (state) => {
      state.loading = true;
    },
    [getAuditions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action?.payload?.message;
    },
    [getAuditions.fulfilled]: (state, action) => {
      state.listAuditions = action?.payload;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
    [getAuditionsQuestion.pending]: (state) => {
      state.loading = true;
    },
    [getAuditionsQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getAuditionsQuestion.fulfilled]: (state, action) => {
      state.listResultAuditions = action?.payload;
      state.paginate = action?.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});

export const { actionRefresh } = userContestSlice.actions;

const { reducer: userContestReducer } = userContestSlice;
export default userContestReducer;
