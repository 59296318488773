export const REGEX = {
  PHONE: /^((\+)33|0)[1-9](\d{2}){4}$/,
  PART_CODE: /^[a-z0-9]*$/,
};

export const sizeOptions = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export const statusTestOptions = [
  { label: 'Tất cả', value: '' },
  { label: 'Đã chấm', value: 1 },
  { label: 'Chưa chấm', value: 0 },
];
