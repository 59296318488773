import request from 'services/request';

export const getRolesApi = async () =>
  request({
    url: `/admin/roles`,
    method: 'GET',
  });

export const getAuthorObjectApi = async () =>
  request({
    url: '/admin/roles/list-when-create',
    method: 'GET',
  });

export const getRoleDetailApi = async (id) =>
  request({
    url: `/admin/roles/detail/${id}`,
    method: 'GET',
  });

export const updateRoleApi = async (id, data) =>
  request({
    url: `/admin/roles/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const createRoleApi = async (data) =>
  request({
    url: `/admin/roles/create`,
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const createAuthorObjectApi = async (data) =>
  request({
    url: `/admin/roles/create-roles`,
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const deleteRoleApi = async (data) =>
  request({
    url: `/admin/roles/delete-role`,
    method: 'DELETE',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteRoleUserApi = async (data) =>
  request({
    url: `/admin/roles/delete-role-user`,
    method: 'DELETE',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
