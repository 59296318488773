import * as yup from 'yup';
import i18n from 'services/i18n';

export const Schema = yup.object({
  email: yup
    .string()
    .email(i18n.t('validation:invalidEmail'))
    .required(i18n.t('validation:pleaseInputEmail')),
  confirmEmail: yup
    .string()
    .oneOf([yup.ref('email'), null], i18n.t('validation:pleaseInputEmailAgain')),
});
