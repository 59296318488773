const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  adminLayout: true,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,

  reducers: {
    changeAdminLayout: (state, action) => {
      state.adminLayout = action.payload;
    },
  },
});

export const { changeAdminLayout } = layoutSlice.actions;
const { reducer: layoutReducer } = layoutSlice;
export default layoutReducer;
