import request from 'services/request';

export const getListCategories = async (params) =>
  request({
    url: '/category-question/list',
    method: 'GET',
    params,
  });

export const getListPartner = async (params) =>
  request({
    url: '/donor/list',
    method: 'GET',
    params,
  });

export const addListPartner = async (data) =>
  request({
    url: '/donor/create',
    method: 'POST',
    data,
  });

  export const updatePartnerApi = async (id, data) =>
  request({
    url: `/donor/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

  export const deletePartnerApi = async (id) =>
  request({
    url: `/donor/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const createCategories = async (data) =>
  request({
    url: '/category-question/create',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const createRules = async (data) =>
  request({
    url: '/regulation/create',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });


export const deleteCatalogApi = async (id) =>
  request({
    url: `/category-question/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const updateCatalogApi = async (id, data) =>
  request({
    url: `/category-question/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getListCategoriesDetailApi = async (params) =>
  request({
    url: `/regulation/${params?.id}`,
    method: 'GET',
    params,
  });

export const deleteCategoriesDetailgApi = async (id) =>
  request({
    url: `admin/categories/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteCourseFromCategoriesApi = async (id) =>
  request({
    url: `admin/categories/delete-category-course/${id}`,
    method: 'PUT',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: false,
  });
