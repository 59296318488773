/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

export const Content = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.3;
  color: ${({ $type }) => ($type === 'success' ? '#085d08' : '#bc0000')};
  width: 100%;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Icon = styled.img`
  margin: 0 16px 0 8px;
`;

export const MessageWrapper = styled.ul``;

export const MessageItem = styled.li``;

export default function ToastMessage({ message, type }) {
  return (
    <Wrapper>
      <Content $type={type}>
        <MessageWrapper>
          {message.map((m,index) => (
            <MessageItem key={index}>{m}</MessageItem>
          ))}
        </MessageWrapper>
      </Content>
    </Wrapper>
  );
}
