import styled, { css } from 'styled-components';
import { Layout as La } from 'antd';

const { Header, Content } = La;

export const Layout = styled(La)`
  min-height: 100vh;
  background: #ffffff;
  .mainColor {
    color: ${({ theme }) => theme.colors.mainColor};
  }
`;

export const Contents = styled(Content)`
  margin-top: ${(props) => (props.showHeader ? '0px' : '60px')};
  background: #f8f8f8;
`;

export const Hd = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  &.ant-layout-header {
    background: #ffffff !important;
    height: 100%;
    width: 100%;
    padding: 0 0;
  }
`;

export const HdContent = styled.div`
  display: block;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 92px;
  background: #ffffff !important;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06) !important;
  padding: 0 32px;
`;

export const WrapMenu = styled.div`
  display: block;
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
  .ant-menu {
    height: 92px;
    display: flex;
    align-items: center;
    .ant-menu-item-selected {
      display: flex;
      border-bottom: 1px solid orange;
      background: #fff !important;
      height: 100%;
      align-items: center;
    }
  }
  .ant-menu .ant-menu-item,
  .ant-menu .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu .ant-menu-submenu .ant-menu-item {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .ant-menu .ant-menu-item.ant-menu-item-selected {
    border-bottom: 1px orange 16px;
  }
  .ant-menu-horizontal {
    border: none;
    text-transform: uppercase;
  }
  .ant-menu-item {
    padding: 0 12px !important;
  }
  .ant-menu-title-content {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: #000000;
    text-transform: uppercase;
  }
  .ant-menu-submenu-open,
  .ant-menu-item-selected {
    .iconMenu {
      /* color: ${({ theme }) => theme.colors.mainColor}; */
    }
  }
`;

export const DFlex = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
