import styled, { css } from 'styled-components';

export const Heading1 = styled.h2`
  text-transform: ${({ $textTransform }) => $textTransform || 'uppercase'};
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 1px;
  color: ${({ $color }) => $color || '#2C2C2C'};

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `};
`;

export const Heading2 = styled.h3`
  text-transform: ${({ $textTransform }) => $textTransform || 'capitalize'};
  font-size: ${({ $fontSize }) => $fontSize || '18px'};
  font-weight: ${({ $fontWeight }) => $fontWeight || '700'};
  line-height: 24px;
  letter-spacing: 1px;
  color: ${({ $color }) => $color || '#00000'};
  border-bottom: ${({ $borderBottom }) => $borderBottom};
  width: fit-content;

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};

  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `};
`;

export const Text = styled.p`
  font-style: ${({ $fontStyle }) => $fontStyle || 'normal'};
  font-weight: ${({ $fontWeight }) => $fontWeight || 400};
  font-size: 14px;
  color: ${({ $color }) => $color || '#2C2C2C'};
  ${(props) =>
    props.active &&
    css`
      color: ${({ $color }) => $color || '#279415'};
    `};
  ${(props) =>
    props.create &&
    css`
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #000000;
    `};
  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${({ $lineHeight }) => $lineHeight || '16px'};
    `};
  ${({ $underline }) =>
    $underline &&
    css`
      text-decoration: underline;
    `};

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `};
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `};
  ${({ overFlow }) =>
    overFlow &&
    css`
      overflow: ${overFlow};
    `};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  ${({ fontStyle }) =>
    fontStyle &&
    css`
      font-style: ${fontStyle};
    `};
  ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${({ $fontSize }) => $fontSize || '14px'};
    `};
  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 500;
      font-size: 14px;
    `}
  ${({ IsItalic }) =>
    IsItalic &&
    css`
      font-size: 14px;
      line-height: 24px;
      font-style: italic;
    `}
`;
