export default {
  btnSearch: 'Tìm kiếm',
  showText: 'Hiển thị tất cả câu hỏi',
  showNews: 'Hiển thị tất cả tin tức',
  textDelete: 'XÓA',
  textInput: 'IMPORT',
  textOutput: 'EXPORT',
  advancedFilters: 'Bộ lọc nâng cao',
  textShow: 'Hiển thị',
  labelSelectAll: 'Chọn tất cả',
};
