import request from 'services/request';

export const getListOverviewApi = async (params) =>
  request({
    url: 'dashboard',
    method: 'GET',
    params,
  });

export const getListOverviewstatisticsApi = async (params) =>
  request({
    url: 'dashboard/statistics',
    method: 'GET',
    params,
  });

export const getListOverviewschedulesApi = async (params) =>
  request({
    url: 'schedules',
    method: 'GET',
    params,
  });

export const getListNotificationApi = async (params) =>
  request({
    url: '/admin/notifications/list',
    method: 'GET',
    params,
  });

export const readNotificationApi = async (id) =>
  request({
    url: `/admin/notifications/read-detail/${id}`,
    method: 'GET',
  });

export const getChartDataApi = async (params) =>
  request({
    url: 'dashboard/statistics',
    method: 'GET',
    params,
  });
  export const getFooterDataApi = async (params) =>
  request({
    url: '/home-setting/detail',
    method: 'GET',
    params,
  });
