export default {
  discount: 'Giảm giá',
  answer: 'Mua 1 tặng 1',
  answerTwo: 'Khuyến mãi',
  answerEnd: 'Tất cả phương án trên',
  result: 'Đáp án',
  refer: 'Tham khảo câu trả lời tại:',
  webSite: 'domain.com',
  contentTitle: 'Câu',
  true: 'Đúng',
  false: 'Sai',
  null: 'Không hoàn thành',
};
