import request from 'services/request';

export const getPolicyApi = async (params) =>
  request({
    url: '/policy/detail',
    method: 'GET',
    params,
  });

export const updatePolicyApi = async (data) =>
  request({
    url: '/admin/policy/create',
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
