import request from 'services/request';

export const getListHotNewsUserApi = async (data) =>
  request({
    url: `user/news/list-new-highlight`,
    method: 'GET',
    data,
  });

export const getLatestNewsApi = async (data) =>
  request({
    url: `user/news/list-new-latest`,
    method: 'GET',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getNewsUserDetailApi = async (id) =>
  request({
    url: `/user/news/detail/${id}`,
    method: 'GET',
    id,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getReadNewsApi = async (data) =>
  request({
    url: `user/news/list-new-most-read`,
    method: 'GET',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

  export const detailNewsApi = async (id) =>
  request({
    url: `/news/${id}`,
    method: 'GET',
    id,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });