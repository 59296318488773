import request from 'services/request';

export const getListCompetitionApi = async (data) =>
  request({
    url: '/competition-manager/list',
    method: 'POST',
    data,
  });
export const getListCreatorApi = async (data) =>
  request({
    url: '/competition-manager/list-created',
    method: 'GET',
    data,
  });
export const getDetailsCompetitionApi = async (id) =>
  request({
    url: `competition-manager/result/${id}`,
    method: 'GET',
  });
export const getResultCompetitionApi = async (params) =>
  request({
    url: `competition-manager/result`,
    params,
    method: 'GET',
  });
export const createCompetitionApi = async (data) =>
  request({
    url: `competition-manager/create`,
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const deleteListCompetitionApi = async (id) =>
  request({
    url: `/competition-manager/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
export const updateCompetitionApi = async (id, data) =>
  request({
    url: `/competition-manager/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
