import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import useTranslation from 'hooks/useTranslation.js';
import { yupResolver } from '@hookform/resolvers/yup';

import { resetPassV3Api } from 'services/apis/other/auth';
import { ROUTES } from 'routes/constants';

import Input from 'components/Form/InputField';
import Button from 'components/commons/Button';

import logo from '../Image/logo.svg';
import iconpass from '../Image/pass.svg';

import eye from '../Image/eye.svg';
import iconShow from '../Image/iconShow.svg';

import { resetpassV2Schema } from './constants';
import * as S from './styled';

function ResetPasswordPage() {
  const { t } = useTranslation('resetPasswordPage');
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [savePass, setSavePass] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [token, setToken] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resetpassV2Schema), mode: 'onChange' });
  const resetPassValidation = useForm({
    resolver: yupResolver(resetpassV2Schema),
    mode: 'onChange',
  });
  useEffect(() => {
    if (localStorage.getItem('user_code')) {
      setValue('user_code', localStorage.getItem('user_code'));
      setValue('password', localStorage.getItem('password'));
      setSavePass(true);
    }
  }, []);

  const resetPass = async (data) => {
    const emailData = location?.state?.update;
    const res = await resetPassV3Api({ ...data, email: emailData });
    if (res.status === 200) {
      history.push(ROUTES.FORGOT_PASSWORD_SUCCESS);
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <img style={{ width: '230px' }} src={logo} alt=" " />
      </S.Header>
      <S.Form onSubmit={handleSubmit(resetPass)}>
        <S.Title>{t('resetpassword')}</S.Title>
        <S.Label>{t('newpassword')}</S.Label>
        <Input
          $height="52px"
          icon={iconpass}
          iconPass={show ? iconShow : eye}
          placeholder={t('newpassword')}
          name="password"
          type={show ? 'text' : 'password'}
          set_show={setShow}
          show={show}
          error={errors?.password?.message}
          register={register}
        />
        <S.Label>{t('inputnewpassword')}</S.Label>
        <Input
          $height="52px"
          icon={iconpass}
          iconPass={show ? iconShow : eye}
          placeholder={t('inputnewpassword')}
          name="password_confirmation"
          type={show ? 'text' : 'password'}
          set_show={setShow}
          show={show}
          error={errors?.password_confirmation?.message}
          register={register}
        />
        <Button fontSize="16px" type="submit" width="100%">
          {t('update')}
        </Button>
      </S.Form>
    </S.Wrapper>
  );
}

export default ResetPasswordPage;
