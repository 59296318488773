import request from 'services/request';

export const getListNewsApi = async (params) =>
  request({
    url: `/news/list`,
    method: 'GET',
    params,
  });

export const createNewsApi = async (data) =>
  request({
    url: `/news/create`,
    method: 'POST',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const deleteNewsApi = async (id) =>
  request({
    url: `/news/delete/${id}`,
    method: 'DELETE',
    id,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const updateNewsApi = async (id, data) =>
  request({
    url: `/news/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const detailNewsApi = async (id) =>
  request({
    url: `/news/${id}`,
    method: 'GET',
    id,
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
