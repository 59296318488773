export default {
  titleTable: 'Danh sách bộ câu hỏi',
  titleSetOfQuestion: 'Bộ câu hỏi',
  titleAction: 'Hành động',
  titleShowAll: 'Tất cả danh mục',
  addSetOfQuestion: 'Thêm bộ câu hỏi',
  enterSetOfQuestion: 'Nhập tên bộ câu hỏi',
  questionNumber: 'Số câu hỏi',
  nameList: 'Tên danh mục',
  editSetOfQuestion: 'Sửa bộ câu hỏi',
  deletelistquestion: 'Xóa bộ câu hỏi',
  desdelete: 'Bạn có chắc muốn xóa bộ câu hỏi không',
  desdeleteHaveQuestion: 'Bộ câu hỏi đã có câu hỏi, không thể xóa',
  editTitle: 'Sửa danh mục',
  titleButtonEdit: 'SỬA',
  labelDis: 'Tên danh mục',
  placeholderAddDis: 'Khóa học nhân viên mới',
};
