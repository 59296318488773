import styled, { css } from 'styled-components';
import { Layout as La } from 'antd';

const { Header, Content, Sider } = La;

export const Layout = styled(La)`
  min-height: 100vh;
  background: #e5e5e5;
  .mainColor {
    color: ${({ theme }) => theme.colors.mainColor};
  }
  .button-create-competition {
    display: flex;
    background: #f9f4f4;
    border: none;
    border-radius: 8px;
    font-weight: 700;
    padding: 9px 15px;
    height: 40px;
    color: ${({ theme }) => theme.colors.mainColor};
    align-items: center;
    transition: all 0.3s;
    .iconButton {
      transition: all 0.3s;
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.mainColor};
      path {
        transition: all 0.3s;
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.mainColor};
      color: #fff;
      .iconButton path {
        fill: #ffff;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .button-create-task {
      .iconButton {
        margin-right: 0;
      }
    }
  }
`;

export const Hd = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  font-weight: 700;
  width: calc(100% - 250px);
  &.menu-collapsed {
    width: 100%;
  }
  &.ant-layout-header {
    height: 60px;
    background: #ffffff !important;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06) !important;
    padding: 0 25px;
  }
  .button-back {
    padding: 0;
    border: none;
    background: transparent;
    color: ${({ theme }) => theme.colors.mainColor};
  }
`;

export const HDSB = styled.div`
  display: block;
  margin-bottom: 90px;
  padding-left: 20px;
`;

export const WrapMenu = styled.div`
  display: block;
  height: calc(100vh - 220px);
  overflow: auto;
  .ant-menu-submenu-open,
  .ant-menu-item-selected {
    .iconMenu {
      color: ${({ theme }) => theme.colors.mainColor};
    }
  }
`;

export const Sd = styled(Sider)`
  padding: 15px 5px 20px 0;
  position: sticky;
  margin-top: 0;
  top: 0;
  height: 100vh;
  overflow: auto;
  ${({ showTab }) =>
    showTab &&
    css`
      padding: 32px 0 32px 4px;
    `};

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Contents = styled(Content)`
  padding: 25px 20px;
  background: #f8f8f8 !important;
  margin-top: 60px;
`;

export const WrapParent = styled.div`
  display: flex !important;
  flex-direction: column !important;
  ${({ showTab }) =>
    showTab &&
    css`
      margin-bottom: 15px;
      padding-bottom: 15px;
    `};
`;

export const Parent = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  padding: 13px 0;
  display: flex;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.mainColor};
  }
  ${({ showTab }) =>
    showTab &&
    css`
      display: none;
    `}
`;

export const WrapChild = styled.div`
  display: flex !important;
  flex-direction: column !important;
  padding-left: 15px;
`;

export const Child = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textColor};
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0px 10px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  .iconMenu {
    color: ${({ theme }) => theme.colors.mainColor};
  }
  ${({ active }) =>
    active &&
    css`
      background: #ffffff;
      border-radius: 22px 0px 0px 22px;
      color: #2c2c2c;
    `}
`;

export const DFlex = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
export const LogOut = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #868a96;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 70%;
  background: #eaeaea;
  border-radius: 8px;
  cursor: pointer;
  gap: 0 12px;
`;
export const WrapLogOut = styled.div`
  display: flex !important;
  flex-direction: column !important;
  padding-left: 18px;
`;

export const WrapBell = styled.div`
  position: relative;
  cursor: pointer;

  .noti-qty {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #eb222c;
    border: 2px solid #fff;
    z-index: 3;
    position: absolute;
    top: 20px;
    right: -4px;
    text-align: center;
  }

  .qty {
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    height: 100%;
    top: -3px;
    right: 1px;
    z-index: 4;
  }
`;
