export default {
  contestIsOn: 'Cuộc thi đang diễn ra',
  textTimeEnd: 'Thời gian còn lại',
  days: 'NGÀY',
  hours: 'GIỜ',
  minutes: 'PHÚT',
  seconds: 'GIÂY',
  textContent: 'Tin tức và sự kiện',
  textSubContent: 'Đừng bỏ lỡ những tin tức và sự kiện mới nhất',
  contestComingUp: 'Cuộc thi sắp diễn ra',
  neverHappened: 'CHƯA DIỄN RA',
  ruleContest: 'Nội Quy Cuộc Thi',
  inforContact: 'Nhập Nội Quy Liên Hệ',
  labelName: 'Họ và Tên * ',
  labelEmail: 'Email * ',
  labelSDT: 'Số điện thoại * ',
  placeholderName: 'Nhập họ và tên',
  placeholderEmail: 'Nhập email',
  placeholderSDT: 'Nhập số điên thoại',
  inforComf: 'Thông tin liên hệ của bạn sẽ được dùng làm tài khoản đăng nhập.',
  inforComfEmail: 'Lưu ý: Điền đúng thông tin để nhận được email.',
  takeExam: 'VÀO THI',
};
