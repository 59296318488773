export default {
  addNew: 'Thêm mới',
  listPartner: 'Danh sách nhà tài trợ và đối tác',
  action: 'Hành động',
  edit: 'Sửa',
  understood: 'Đã hiểu',
  wisdom: 'Trí tuệ',
  showall: 'Hiển thị tất cả',
  namePartner: 'Tên nhà tài trợ',
  images: 'Hình ảnh',
  logoPartner: 'Logo nhà tài trợ',
  desLogo: 'Tối đa 1 ảnh, định dạng jpg, png',
  addPartner: 'Thêm nhà tài trợ và đối tác',
  inputNamePartner: 'Nhập tên nhà tài trợ',
  website: 'Website',
  inputNameWebsite: 'Nhập tên website',
  falseFormat: 'Sai định dạng',
  editPartner: 'Sửa nhà tài trợ và đối tác',
  deletePartner: 'Xóa nhà tài trợ và đối tác',
  desDeletePartner: 'Bạn có chắc chắn muốn xoá không?',
  namePartnerDetail: 'Tên nhà tài trợ',
  detailPartner: 'Chi tiết nhà tài trợ và đối tác',
  update: 'Cập nhật',
};
