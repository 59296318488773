import styled from 'styled-components';
import { Statistic } from 'antd';
import bgpp from './images/bgpp.png';

export const WrapperGlobal = styled.div`
  padding: 50px 50px 50px;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)), url(${bgpp});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  padding: 28px 36px;
  border-radius: 8px;
  background-color: #ffffff;
  label {
    font-size: 12px;
  }
  a {
    text-decoration: underline;
    color: #eb222c;
  }
`;

export const Header = styled.div`
  flex-basis: 20%;
  text-align: center;
  margin-bottom: 70px;
  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    margin-top: 40px;
    margin-bottom: 20px;

    @media (max-height: 800px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
`;

export const Title = styled.h3`
  font-style: normal;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #303841;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  padding-bottom: 28px;
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  gap: 24px;
  button {
    flex-grow: 1;
  }
`;

export const OtpInputBox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const OtpInput = styled.input`
  width: 61px;
  height: 70px;
  border: 1px solid #dce0ea;
  border-radius: 16px;
  font-size: 32px;
  line-height: 42px;
  font-weight: 500;
  color: #0a0822;
  margin: 0 4px;
  text-align: center;
`;

export const CountDownCustom = styled(Statistic.Countdown)`
  .ant-statistic-content-value {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #eb222c;
  }
`;

export const Form = styled.form`
  width: 459px;
  background: #ffffff;
  box-shadow: 0px 41px 56px rgba(0, 18, 101, 0.12);
  border-radius: 8px;
  padding: 40px 48px;
  max-height: 500px;
`;
