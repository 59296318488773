import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Menu } from 'antd';

import { useHistory, useLocation, Link } from 'react-router-dom';
import { ROUTES, ROUTES_USER } from 'routes/constants';
import { getListNotification } from 'store/user/overviewSlice';

import Button from 'components/commons/Button';
import Footer from 'components/Users/Footer';
import { Text } from 'components/commons/Typo';

import webStorage from 'utils/webStorage';

import logo from 'assets/icons/logo-sum.svg';
import lock from 'assets/icons/lock-login.svg';
import avatar from 'assets/icons/avatar.svg';

import useTranslation from '../../hooks/useTranslation';

import * as S from './styled';

import { data } from './mock-data';

export default function LayoutUser({ children }) {
  const { t } = useTranslation('layoutUser');

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentUser = useSelector((state) => state.authReducer.currentUser);
  const info = useSelector((state) => state.authReducer.currentUser);
  const userInfo = useSelector((state) => state.userReducer?.userInfo);

  const accessToken = webStorage.getToken();
  const [active, setActive] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  const listHeader = [
    ROUTES.LOGIN,
    ROUTES.FORGOT_PASSWORD,
    ROUTES.FORGOT_PASSWORD_SUCCESS,
    ROUTES.FORGOT_PASSWORD_MODAL,
    ROUTES.RESET_PASSWORD_PAGE,
  ];
  const listFooter = [
    ROUTES.LOGIN,
    ROUTES.FORGOT_PASSWORD,
    ROUTES.FORGOT_PASSWORD_SUCCESS,
    ROUTES.FORGOT_PASSWORD_MODAL,
    ROUTES.RESET_PASSWORD_PAGE,
  ];

  useEffect(() => {
    dispatch(getListNotification());
  }, [dispatch]);

  useEffect(() => {
    setActive(location?.pathname);
  }, [location?.pathname]);

  return (
    <S.Layout>
      {!listHeader.includes(location.pathname) && (
        <S.HdContent>
          <S.Hd>
            <S.DFlex>
              <Link to={ROUTES.HOME}>
                <img src={logo} alt="logo" />
              </Link>
            </S.DFlex>
            <S.WrapMenu>
              <Menu mode="vertical" defaultSelectedKeys="P1">
                {data?.map((item) => (
                  <>
                    {item?.menus?.length > 0 ? (
                      <Menu.SubMenu key={item?.id} title={t(item?.title)}>
                        {item?.menus?.map((k) => (
                          <Menu.Item
                            key={k?.id}
                            onClick={() => {
                              history.push(k?.link);
                              if (window.screen.width < 767) {
                                setCollapsed(!collapsed);
                              }
                            }}
                          >
                            {t(k?.title)}
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    ) : (
                      <Menu.Item
                        key={item?.id}
                        onClick={() => {
                          history.push(item?.link);
                          if (window.screen.width < 767) {
                            setCollapsed(!collapsed);
                          }
                        }}
                      >
                        {t(item?.title)}
                      </Menu.Item>
                    )}
                  </>
                ))}
              </Menu>
            </S.WrapMenu>
            {accessToken === undefined ? (
              <Button
                onClick={() => {
                  history.push(ROUTES.LOGIN);
                }}
                style={{ gap: '8px' }}
              >
                <img src={lock} alt="logo" />
                <div className="button-login">{t('login')}</div>
              </Button>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Link to={ROUTES_USER.PROFILE_PAGE}>
                  <Avatar size={40} src={userInfo?.anhThe || avatar}></Avatar>
                </Link>
                <Text isBold style={{ color: '#303030', textTransform: 'capitalize' }}>
                  {info?.name}
                </Text>
              </div>
            )}
          </S.Hd>
        </S.HdContent>
      )}

      <S.Contents showHeader={listHeader.includes(location.pathname)}>{children}</S.Contents>
      {!listFooter.includes(location.pathname) && <Footer />}
    </S.Layout>
  );
}
