import request from 'services/request';

export const getListRulesApi = async (params) =>
  request({
    url: '/regulation/list',
    method: 'GET',
    params,
  });
export const updateListRulesApi = async (id, params) =>
  request({
    url: `/regulation/update/${id}`,
    method: 'PUT',
    params,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const deleteListRulesApi = async (id) =>
  request({
    url: `regulation/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });
export const getDetailsRulesApi = async (id, get) =>
  request({
    url: `regulation/${id}`,
    method: 'GET',
    get,
  });
