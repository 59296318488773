export default {
  content1: 'Nội dung 1',
  content2: 'Nội dung 2',
  content3: 'Nội dung 3',
  branch: 'Logo đơn vị liên kết',
  website: 'Website',
  enterWebsite: 'Nhập website',
  logo: 'Logo Phong & Partner',
  labelContent: 'Nội dung',
  description: 'Tối đa 1 ảnh, định dạng jpg, png',
  uploadLogo: 'Upload logo',
  uploadImage: 'Upload ảnh',
  save: 'Lưu',
  title: 'Tiêu đề',
  enterTitle: 'Nhập tiêu đề',
};
