import React from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { toast } from 'react-toastify';

import { API_SERVER, ACCESS_TOKEN } from '../constants/configs';
import ToastMessage from '../components/Toast';
import webStorage from '../utils/webStorage';

const baseApiConfig = {
  baseURL: `${API_SERVER}/api/v1`,
  headers: {
    'content-type': 'application/json',
  },
  timeout: 600000, // 600s = 10 mins
  paramsSerializer: (params) => queryString.stringify(params),
};

const SESSION_EXPIRED_STATUS_CODE = 401;

const baseApiClient = axios.create(baseApiConfig);

const request = ({
  enableFlashMessageError = true,
  enableFlashMessageSuccess = false,
  isAuth = true,
  ...options
}) => {
  if (isAuth) {
    const accessToken = webStorage.getToken();
    baseApiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  const onSuccess = (response) => {
    if (enableFlashMessageSuccess && response?.data?.message) {
      const messageList = [];
      messageList.push(response.data.message);
      toast(<ToastMessage type="success" message={messageList} />);
    }
    return response;
  };

  const onError = (error) => {
    if (
      error.response.status !== SESSION_EXPIRED_STATUS_CODE &&
      enableFlashMessageError &&
      error.response?.data?.message
    ) {
      const messageList = [];
      const errData = error.response.data;
      if (errData.message && errData.message.includes('Validation')) {
        Object.values(errData.errors).forEach((errArr, _, arr) => {
          errArr.forEach((err) => {
            messageList.push(err);
          });
        });
      } else messageList.push(errData.message);
      toast(<ToastMessage type="error" message={messageList} />);
    }

    if (error.response.status === SESSION_EXPIRED_STATUS_CODE) {
      webStorage.remove(ACCESS_TOKEN);
    }

    return Promise.reject(error.response);
  };

  return baseApiClient(options).then(onSuccess).catch(onError);
};

export default request;
