/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import logo from 'assets/icons/logo-footer.svg';
import arrow from 'assets/icons/arrow-footer.svg';
import fb from 'assets/icons/Facebook - Negative.svg';
import yt from 'assets/icons/YouTube - Negative.svg';
import tw from 'assets/icons/Twitter - Negative.svg';
import { getFooterData } from 'store/user/overviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ASSETS_URL } from 'constants/configs';
import { ROUTES } from 'routes/constants';

import useTranslation from 'hooks/useTranslation';
import * as S from './styled';

export default function Footer() {
  const { t } = useTranslation('footer');
  const dispatch = useDispatch();
  const listFooter = useSelector((state) => state?.userOverviewReducer?.listFooter);

  useEffect(() => {
    dispatch(getFooterData());
  }, [dispatch]);

  return (
    <S.Wrapper>
      <footer className="bg-light text-center text-lg-start foo">
        <div className="container p-4">
          <div className="row">
            <div className="col-lg-3  mb-4 mb-md-0">
              <h5 className="text-uppercase d-flex logo-all">
                <img className="image-logo1" src={`${ASSETS_URL}/${listFooter?.logo}`} alt="" />
                <img
                  className="image-logo"
                  src={`${ASSETS_URL}/${listFooter?.logo_linked}`}
                  alt=""
                />
              </h5>
              <ul className="list-unstyled mb-0">
                <li>
                  <div className="text-dark">{listFooter?.description}</div>
                </li>
              </ul>
            </div>

            <div className="col-lg-4  mb-4 mb-md-0 address">
              <h5 className="text-uppercase mb-0 lawer">{t('titleColumTwo')}</h5>

              <ul className="list-unstyled">
                <li className="footer-column-3">
                  <div className="text-dark">
                    <span className="title-detail">{t('headQuarters')}</span>{' '}
                    {listFooter?.address_main}
                  </div>
                  <div className="text-dark">
                    {t('phoneHeadQuarters')}
                    {listFooter?.phone_main}
                  </div>
                </li>
                {listFooter?.branch?.map((item, index) => (
                  <li className="footer-column-3" key={index}>
                    <div className="text-dark">
                      {' '}
                      <span className="title-detail">
                        {t('branchOne')} {index + 1}:
                      </span>{' '}
                      {item?.address}{' '}
                    </div>
                    <div className="text-dark">
                      {t('phoneAddressOne')} {item?.phone_number}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-3  mb-4 mb-md-0">
              <h5 className="text-uppercase ">{t('titleColumThree')}</h5>
              <ul className="list-unstyled mb-0">
                <li className="footer-column-3">
                  <a href="#!" className="text-dark">
                    <span className="title-detail">{t('address')}</span>{' '}
                    {listFooter?.address_linked}
                  </a>
                </li>
                <li className="footer-column-3">
                  <a href="#!" className="title">
                    {t('phone')}
                  </a>
                  <a href="#!" className="text-dark">
                    {' '}
                    {listFooter?.phone_linked}
                  </a>
                </li>
                <li className="footer-column-3">
                  <a href="#!" className="title">
                    {t('email')}
                  </a>
                  <a href="#!" className="text-dark">
                    {' '}
                    {listFooter?.email_linked}
                  </a>
                </li>
                <li className="footer-column-3">
                  <a href="#!" className="title">
                    {t('webSite')}
                  </a>
                  <a href="#!" className="text-dark">
                    {' '}
                    {listFooter?.website_linked}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2  mb-4 mb-md-0">
              <h5 className="text-uppercase mb-0">{t('link')}</h5>
              <ul className="list-unstyled">
                <li className="link-footer">
                  <img src={arrow} alt="" />
                  <a href={ROUTES.INTRODUCE} className="text-dark-right">
                    {t('introduce')}
                  </a>
                </li>
                <li className="link-footer">
                  <img src={arrow} alt="" />
                  <a href={ROUTES.LIST_NEWS_USER_PAGE} className="text-dark-right">
                    {t('news')}
                  </a>
                </li>
                <li className="link-footer">
                  <img src={arrow} alt="" />
                  <a href={ROUTES.CONTACT} className="text-dark-right">
                    {t('contact')}
                  </a>
                </li>
              </ul>
              <h5 className="text-uppercase mb-0 follow">{t('followUs')}</h5>
              <ul className="list-unstyled">
                <li className="link-footer-image">
                  <a
                    target="_blank"
                    href={listFooter?.link_facebook}
                    className="text-dark"
                    rel="noreferrer"
                  >
                    <img src={fb} alt="" />
                  </a>
                  <a
                    target="_blank"
                    href={listFooter?.link_twitter}
                    className="text-dark"
                    rel="noreferrer"
                  >
                    <img src={tw} alt="" />
                  </a>
                  <a
                    target="_blank"
                    href={listFooter?.link_youtube}
                    className="text-dark"
                    rel="noreferrer"
                  >
                    <img src={yt} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center p-3">{t('contentFooter')}</div>
      </footer>
    </S.Wrapper>
  );
}
