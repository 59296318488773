import React, { useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { ROUTES } from 'routes/constants';
import useTranslation from 'hooks/useTranslation.js';
import { resetPassApi, resetPassV2Api, resetPassV3Api } from 'services/apis/other/auth';
import { Heading1, Heading2 } from 'components/commons/Typo';

import Input from 'components/Form/InputField';
import Button from 'components/commons/Button';
import { emailSchema } from './constants';

import * as S from './styled';
import logo from '../Image/logo.svg';

function ForgotPassModal({ show, setShow }) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('forgotPassModal');
  const refOtp1 = useRef(null);
  const refOtp2 = useRef(null);
  const refOtp3 = useRef(null);
  const refOtp4 = useRef(null);
  const [modal, setModal] = useState('modalOtp');
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [otp, setOtp] = useState('');
  const [countDown, setCountDown] = useState(Date.now() + 4 * 60 * 1000);
  const [resendDisabled, setResendDisabled] = useState(true);
  const emailValidation = useForm({ resolver: yupResolver(emailSchema), mode: 'onChange' });

  const resetPassValidation = useForm({
    resolver: yupResolver(),
    mode: 'onChange',
  });

  const resetForgotModal = () => {
    emailValidation?.reset();
    resetPassValidation?.reset();
    setOtp('');
    setModal('modalEmail');
  };

  const handleEmailSubmit = async () => {
    const emailCode = location?.state?.update;
    const res = await resetPassApi({ email: emailCode });
    if (res?.status === 200) {
      setCountDown(Date.now() + 4 * 60 * 1000);
      setResendDisabled(true);
    }
  };

  const handleOtpSubmit = async () => {
    const emailData = location?.state?.update;
    const res = await resetPassV2Api({ code: otp, email: emailData });
    if (res?.status !== 400) {
      history.push(ROUTES.RESET_PASSWORD_PAGE, {
        update: emailData,
      });
    }
  };

  const handleOptInput = (oldRef, currentRef, nextRef) => {
    if (currentRef?.current?.value === '') oldRef?.current.focus();
    if (currentRef?.current?.value && !nextRef?.current?.value) {
      nextRef?.current.focus();
    }
    const data = [refOtp1, refOtp2, refOtp3, refOtp4].map((ref) => ref.current?.value).join('');
    setOtp(data);
  };

  const handleResetPass = async (data) => {
    const res = await resetPassV3Api({ ...data, ...emailValidation?.getValues() });
    if (res?.status === 200) resetForgotModal();
  };

  const handleComback = () => {
    history.push(ROUTES.FORGOT_PASSWORD);
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleOtpSubmit();
    }
  };

  return (
    <S.WrapperGlobal>
      <S.Header>
        <img src={logo} alt=" " />
      </S.Header>
      <S.Wrapper>
        <Heading1 $color="#303841" textAlign="center" lineHeight="34px" margin="8px 0 32px">
          <S.Title>{t('accuracyEmail')}</S.Title>
        </Heading1>
        <Heading2
          style={{ fontWeight: 400, width: '360px', fontSize: '16px' }}
          $color="#303841"
          textAlign="center"
          margin="0 auto 32px"
          lineHeight="24px"
          $textTransform="normal"
        >
          {t('pleaseIinputEmal')}
        </Heading2>
        <S.OtpInputBox>
          <S.OtpInput
            maxLength={1}
            ref={refOtp1}
            onChange={() => handleOptInput(refOtp1, refOtp1, refOtp2)}
          />
          <S.OtpInput
            maxLength={1}
            ref={refOtp2}
            onChange={() => handleOptInput(refOtp1, refOtp2, refOtp3)}
          />
          <S.OtpInput
            maxLength={1}
            ref={refOtp3}
            onChange={() => handleOptInput(refOtp2, refOtp3, refOtp4)}
          />
          <S.OtpInput
            maxLength={1}
            ref={refOtp4}
            onChange={() => handleOptInput(refOtp3, refOtp4, refOtp4)}
            onKeyPress={handleEnter}
          />
        </S.OtpInputBox>

        <Heading2
          style={{ fontWeight: 400, fontSize: '16px' }}
          $color="#303841"
          textAlign="center"
          lineHeight="24px"
          margin="auto"
          $textTransform="normal"
        >
          {t('pleaseOnTime')}
        </Heading2>

        <Heading2
          style={{ fontWeight: 400 }}
          $color="#EB222C"
          textAlign="center"
          margin="0 auto 32px"
          lineHeight="24px"
        >
          <S.CountDownCustom value={countDown} onFinish={() => setResendDisabled(false)} />
        </Heading2>

        <S.Footer>
          <Button $type="gray" borderRadius="8px" onClick={handleComback} fontSize="16px">
            {t('back')}
          </Button>
          <Button
            disabled={otp.length !== 4}
            onClick={() => handleOtpSubmit()}
            fontSize="16px"
            borderRadius="8px"
          >
            {t('next')}
          </Button>
        </S.Footer>

        <a onClick={() => handleEmailSubmit()} aria-hidden="true">
          <Heading2
            style={{ fontWeight: '400', display: resendDisabled ? 'none' : 'block' }}
            $color="#EB222C"
            textAlign="center"
            margin="32px auto 0"
            lineHeight="24px"
            $borderBottom="1px solid #EB222C"
            $textTransform="normal"
          >
            {t('againCode')}
          </Heading2>
        </a>
      </S.Wrapper>
    </S.WrapperGlobal>
  );
}

export default ForgotPassModal;
