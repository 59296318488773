import request from 'services/request';

export const changePasswordsApi = async (data) =>
  request({
    url: '/reset-password-profile',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const getListContestEnteredApi = async (params) =>
  request({
    url: '/competition/joined',
    method: 'GET',
    params,
  });
