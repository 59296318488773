import styled from 'styled-components';
import Radio from 'components/Form/Radio';
import { ReactSVG } from 'react-svg';
import bgpp from './images/bgpp.png';

export const Wrapper = styled.div`
  padding: 50px 50px 0px;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48)), url(${bgpp});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Text = styled.div`
  padding-bottom: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
`;

export const Header = styled.div`
  flex-basis: 20%;
  text-align: center;
  margin-bottom: 70px;
  h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    margin-top: 40px;
    margin-bottom: 80px;

    @media (max-height: 800px) {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
`;

export const Form = styled.form`
  width: 459px;
  background: #ffffff;
  box-shadow: 0px 41px 56px rgba(0, 18, 101, 0.12);
  border-radius: 8px;
  padding: 40px 48px;
  max-height: 500px;
`;

export const Title = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #303841;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  padding-bottom: 28px;
  text-transform: uppercase;
`;

export const ForgotPass = styled.div`
  margin: 12px 0 16px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #d72323;
    background: none;
    cursor: pointer;
  }
`;

export const Register = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  button {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #d72323;
    border: none;
    background: none;
    cursor: pointer;
  }
`;

export const BoxRadio = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 1px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #303841;
  }
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #303841;
  margin-bottom: 8px;
`;

export const RadioCustom = styled(Radio)`
  .ant-radio {
    top: 0.13em;
  }
  .ant-radio-inner {
    width: 13px;
    height: 13px;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 8px;
      min-height: 8px;
      margin: 0;
      width: 8px;
      height: 8px;
    }
  }
`;

export const SVG = styled(ReactSVG)`
  svg {
    color: #1a2542;
    width: ${({ $width }) => $width || '10px'};
    margin-right: 10px;
    margin-bottom: 2px;
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
  color: #d72323;
  margin-bottom: 34px;
`;
