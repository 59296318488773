import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getListRulesApi, getDetailsRulesApi } from 'services/apis/admin/rule';

export const getListRules = createAsyncThunk(
  'rules/GET_LIST_RULES',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getListRulesApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getDetailsRules = createAsyncThunk(
  'rules/GET_DETAILS_RULES',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailsRulesApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const initialState = {
  listRule: [],
  getListDetailRule: [],
  paginate: {},
  details: {},
  totalAmount: {},
  loading: false,
  error: {},
};

const ruleSlice = createSlice({
  name: 'rule',
  initialState,

  extraReducers: {
    [getListRules.pending]: (state) => {
      state.loading = true;
    },
    [getListRules.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListRules.fulfilled]: (state, action) => {
      state.listRule = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },

    [getDetailsRules.pending]: (state) => {
      state.loading = true;
    },
    [getDetailsRules.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getDetailsRules.fulfilled]: (state, action) => {
      state.details = action.payload?.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: rulesReducer } = ruleSlice;
export default rulesReducer;
