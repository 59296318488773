export default {
  nameUser: 'Họ và tên thí sinh',
  showNews: 'Hiển thị tất cả thí sinh',
  createNews: 'Tạo tin tức',
  choice: 'Chọn',
  placeholderInInf: 'Nhập thông tin',
  titleButModel: 'Lọc ngay',
  listNews: 'Danh sách thí sinh',
  participation: 'Ngày tham gia',
  email: 'Email',
  action: 'Hành động',
  numberTest: 'Số cuộc thi tham gia',
  numberPhone: 'Số điện thoại',
  time: 'Thời gian',
  from: 'Từ',
  to: 'Đến',
  deleteFilter: 'Xóa bộ lọc',
  titleDelete: 'Xóa Thí Sinh',
  desDelete: 'Bạn có chắc muốn xóa thí sinh không ?',
  titleModal: 'Bộ lọc nâng cao',
  competition: 'Cuộc thi',
  titleButModal: 'Lọc ngay',
};
