export default {
  nameComoetition: 'Tên cuộc thi',
  timeStart: 'Thời gian bắt đầu',
  timeEnd: 'Thời gian kết thúc',
  typeQuestion: 'Bộ câu hỏi',
  creator: 'Người tạo',
  participants: 'Số thí sinh tham gia',
  action: 'Hành động',
  createCompetition: 'Tạo cuộc thi',
  choice: 'Chọn',
  titleModel: 'Bộ Lọc Nâng Cao',
  titleTable: 'Danh sách cuộc thi',
  showAllQues: 'Hiển thị tất cả cuộc thi',
  placeholderInInf: 'Nhập tên cuộc thi',
  titleCof: 'Xoá Cuộc Thi',
  descCof: 'Bạn có chắc muốn xoá cuộc thi không ?',
  from: 'Từ',
  to: 'Đến',
  titleButModel: 'Lọc ngay',
  time: 'Thời gian',
};
