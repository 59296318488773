export default {
  titleCof: 'Xóa câu hỏi',
  titleModel: 'Bộ lọc nâng cao',
  descCof: 'Bạn có chắc muốn xóa câu hỏi không?',
  titleImg: 'Hình Ảnh',
  titleTextQues: 'Tên câu hỏi',
  titleListQues: 'Bộ câu hỏi',
  titleCategory: 'Lĩnh vực',
  titleCreator: 'Người tạo',
  titleLvDifficul: 'Độ khó',
  titleAction: 'Hành động',
  titleButModel: 'ÁP DỤNG',
  titleTable: 'Danh sách câu hỏi',
  dataName: 'Thêm câu hỏi trắc nghiệm',
  dataNameImg: 'Thêm câu hỏi trắc nghiệm đáp án có hình ảnh',
  levelEasy: 'Dễ',
  levelMedium: 'Trung bình',
  levelDifficult: 'Khó',
  labelCategory: 'Lĩnh vực',
  placeholderField: 'Chọn',
  placeholderInInf: 'Nhập thông tin',
  labelListQues: 'Bộ câu hỏi',
  labelCreator: 'Người tạo',
  labelLvDifficul: 'Độ khó',
  showAllQues: 'Hiển thị tất cả câu hỏi',
  optionQues: 'Loại câu hỏi',
};
