import styled from 'styled-components';
import Radio from 'components/Form/Radio';
import { ReactSVG } from 'react-svg';
import bg from './images/bg.png';

export const Wrapper = styled.div`
  padding: 50px 50px 150px;
  width: 100%;
  height: 100vh;
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Header = styled.div`
  flex-basis: 20%;
  text-align: center;
  margin-bottom: 70px;
  img {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 459px;
  background: #ffffff;
  box-shadow: 0px 41px 56px rgba(0, 18, 101, 0.12);
  border-radius: 16px;
  padding: 40px 48px;
  max-height: 500px;
  .btn-login {
    font-size: 16px;
  }
`;

export const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
  padding-bottom: 28px;
`;

export const ForgotPass = styled.div`
  margin: 12px 0 16px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #000000;
    background: none;
    cursor: pointer;
  }
`;

export const Register = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  button {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #d72323;
    border: none;
    background: none;
    cursor: pointer;
  }
`;

export const BoxRadio = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-left: 1px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #000000;
  margin-bottom: 8px;
`;

export const RadioCustom = styled(Radio)`
  .ant-radio {
    top: 0.13em;
  }
  .ant-radio-inner {
    width: 13px;
    height: 13px;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 8px;
      min-height: 8px;
      margin: 0;
      width: 8px;
      height: 8px;
    }
  }
`;
export const ErrorMessage = styled.div`
  font-size: 14px;
  font-style: italic;
  line-height: 20px;
  text-align: center;
  color: #f6c92b;
  margin-bottom: 34px;
`;
