import React, { useEffect, Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getMe, updateIsFirstAccess } from 'store/other/authSlice';

import Loading from 'components/Loading';

import { ROUTES_ADMIN, ROUTES_USER } from 'routes/constants';

import PropTypes from 'prop-types';
import webStorage from 'utils/webStorage';
import Layout from 'components/LayoutUser';

NonRoute.propTypes = {
  component: PropTypes.any,
};

function NonRoute({ component: Component, ...rest }) {
  const dispatch = useDispatch();
  const isFirstAccess = useSelector((state) => state?.authReducer?.isFirstAccess);
  const currentUser = useSelector((state) => state?.authReducer?.currentUser);
  const { isAuth, isSignUpSuccess } = useSelector((state) => state?.authReducer);

  useEffect(() => {
    if (isFirstAccess) {
      const accessToken = webStorage.getToken();
      if (accessToken) {
        dispatch(getMe());
      } else {
        dispatch(updateIsFirstAccess());
      }
    }
  }, [dispatch, isFirstAccess]);

  if (isFirstAccess) return <Loading />;

  return (
    <Route {...rest}>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Component />
        </Suspense>
      </Layout>
    </Route>
  );
}

export default NonRoute;
