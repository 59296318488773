import request from 'services/request';

export const getListQuestionApi = async (data) =>
  request({
    url: '/question/list',
    method: 'POST',
    data,
  });

export const getListSkillApi = async (params) =>
  request({
    url: '/admin/questions/listSkills',
    method: 'GET',
    params,
  });

export const getListCreatorApi = async (params) =>
  request({
    url: '/question/list/createBy',
    method: 'GET',
    params,
  });

export const createQuestionApi = async (data) =>
  request({
    url: '/question/create',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });

export const deleteQuestionApi = async (id) =>
  request({
    url: `/question/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: true,
  });

export const getDetailsQuestionApi = async (id) =>
  request({
    url: `/question/${id}`,
    method: 'GET',
  });

export const updateQuestionApi = async (id, data) =>
  request({
    url: `/question/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const getListDepartmentQuestionApi = async (params) =>
  request({
    url: '/question-type/list',
    method: 'GET',
    params,
  });
export const deleteListDepartmentQuestionApi = async (id) =>
  request({
    url: `/question-type/delete/${id}`,
    method: 'DELETE',
    enableFlashMessageError: true,
    enableFlashMessageSuccess: false,
  });
export const updateListDepartmentQuestionApi = async (id, data) =>
  request({
    url: `/question-type/update/${id}`,
    method: 'PUT',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
export const createQuestionTypeApi = async (data) =>
  request({
    url: 'question-type/create',
    method: 'POST',
    data,
    enableFlashMessageSuccess: true,
    enableFlashMessageError: true,
  });
