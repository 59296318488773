import { configureStore } from '@reduxjs/toolkit';
import authReducer from './other/authSlice';
import userReducer from './admin/userSlice';
import overviewReducer from './admin/overviewSlice';
import userOverviewReducer from './user/overviewSlice';
import roleReducer from './admin/roleSlice';
import layoutReducer from './other/layoutSlice';
import noPermissionReducer from './other/noPermissionSlice';
import policyReducer from './other/policySlice';
import catalogReducer from './admin/catalogSlice';
import questionReducer from './admin/questionSlice';
import rulesReducer from './admin/ruleSlice';
import competitionReducer from './admin/competitionSlice';
import userStudyForTestReducer from './user/studyForTestSlice';
import newsReducer from './admin/listNewsSlice';
import userContestReducer from './user/homeContestSide';
import userContestEnteredReducer from './user/profileSlice';
import newsUserReducer from './user/userNewsSlice';
import donorReducer from './user/donorSilce';
import introduceReducer from './other/introduceSlice';
import settingReducer from './admin/settingSlice';

export const store = configureStore({
  reducer: {
    layoutReducer,
    authReducer,
    userReducer,
    overviewReducer,
    questionReducer,
    userOverviewReducer,
    roleReducer,
    noPermissionReducer,
    policyReducer,
    catalogReducer,
    rulesReducer,
    competitionReducer,
    userStudyForTestReducer,
    newsReducer,
    userContestReducer,
    userContestEnteredReducer,
    newsUserReducer,
    donorReducer,
    introduceReducer,
    settingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
