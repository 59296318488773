import React from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';

import SuccessForgotPassword from 'containers/OtherPage/SuccessForgotPasswordPage';
import ForgotPassword from 'containers/OtherPage/ForgotPasswordPage';
import ForgotPassModal from 'containers/OtherPage/ForgotPassModal';
import ResetPasswordPage from 'containers/OtherPage/ResetPasswordPage';
import ScrollToTop from 'hooks/ScrollToTop';
import AdminRoute from '../hocs/AdminRoute';
import NonAuthRoute from '../hocs/NonAuthRoute';
import AuthRoute from '../hocs/AuthRoute';
import NonRoute from '../hocs/NonRoute';

import { ROUTES, ROUTES_ADMIN, ROUTES_USER } from './constants';

import LoginPage from '../containers/OtherPage/LoginPage';

const OverviewPage = React.lazy(() => import('../containers/AdminPage/OverviewPage'));
const CategoriesQuestionPage = React.lazy(() =>
  import('../containers/AdminPage/CategoriesQuestionPage')
);
const SetOfQuestionPage = React.lazy(() => import('../containers/AdminPage/SetOfQuestionPage'));
const ManagementSetOfQuestionPage = React.lazy(() =>
  import('../containers/AdminPage/ManagementSetOfQuestionPage')
);
const ManagementAdminPage = React.lazy(() => import('../containers/AdminPage/ManagementAdminPage'));
const ManagementCandidatesPage = React.lazy(() =>
  import('../containers/AdminPage/ManagementCandidatesPage')
);
const DetailManagementCandidatesPage = React.lazy(() =>
  import('../containers/AdminPage/DetailManagementCandidatesPage')
);
const ManagementCompetitionPage = React.lazy(() =>
  import('../containers/AdminPage/ManagementCompetitionPage')
);
const ManagementCompetitionPageDetails = React.lazy(() =>
  import('../containers/AdminPage/ManagementCompetitionPageDetails')
);
const CreateCompetitionPage = React.lazy(() =>
  import('../containers/AdminPage/CreateCompetitionPage')
);
const EditCompetitionPage = React.lazy(() => import('../containers/AdminPage/EditCompetitionPage'));
const ManagementQuestionPage = React.lazy(() =>
  import('../containers/AdminPage/ManagementQuestionPage')
);
const IntroducePage = React.lazy(() => import('../containers/AdminPage/IntroducePage'));

// const CourseCatalogPage = React.lazy(() => import('../containers/AdminPage/CourseCatalogPage'));
const EditQuestionPage = React.lazy(() => import('../containers/AdminPage/EditQuestionPage'));
const ListNewsPage = React.lazy(() => import('../containers/AdminPage/ManagementListNewsPage'));
const ListNewsPageDetail = React.lazy(() => import('../containers/AdminPage/ListNewsPageDetail'));
const EditNewsPage = React.lazy(() => import('../containers/AdminPage/EditNewsPage'));
const AddListNewsPage = React.lazy(() => import('../containers/AdminPage/CreateListNewsPage'));
const PolicyPage = React.lazy(() => import('../containers/AdminPage/PolicyPage'));
const RulesPage = React.lazy(() => import('../containers/AdminPage/RulesPage'));
const PracticePage = React.lazy(() => import('../containers/AdminPage/PracticePage'));
const CreateQuestionPage = React.lazy(() => import('../containers/AdminPage/CreateQuestionPage'));
const ManagementCategoryQuestionPage = React.lazy(() =>
  import('../containers/AdminPage/ManagementCategoryQuestionPage')
);

const ManagementPermissionPage = React.lazy(() =>
  import('../containers/AdminPage/ManagementPermissionPage')
);
const ManagementPartnerPage = React.lazy(() =>
  import('../containers/AdminPage/ManagementPartnerPage')
);
const NotificationPage = React.lazy(() => import('../containers/AdminPage/NotificationPage'));
const ProfilePage = React.lazy(() => import('../containers/AdminPage/ProfilePage'));

const HomePage = React.lazy(() => import('../containers/UserPage/HomePage'));
const PolicyUserPage = React.lazy(() => import('../containers/UserPage/PolicyPage'));
const ImportQuestion = React.lazy(() => import('../containers/AdminPage/ImportQuestionPage'));
const AddCompetition = React.lazy(() => import('../containers/AdminPage/AddCompetitionPage'));
const ProfileUserPage = React.lazy(() => import('../containers/UserPage/ProfilePage'));
const ListNewsUserPage = React.lazy(() => import('../containers/UserPage/ListNewsUserPage'));
const DonorPage = React.lazy(() => import('../containers/UserPage/DonorPage'));
const IntroducePageUser = React.lazy(() => import('../containers/UserPage/IntroducePage'));
const HomeUserPage = React.lazy(() => import('../containers/UserPage/HomeUser'));
const ContesResults = React.lazy(() => import('../containers/UserPage/ContesResultsPage'));
const DetailNewsUserPage = React.lazy(() => import('../containers/UserPage/DetailNewsUserPage'));
const HomeUser = React.lazy(() => import('../containers/UserPage/HomePageUser'));

const DepartmentQuestionPageDetail = React.lazy(() =>
  import('../containers/AdminPage/DepartmentQuestionPageDetail')
);
const CategoriesQuestionPageDetail = React.lazy(() =>
  import('../containers/AdminPage/CategoriesQuestionPageDetail')
);
const ManagementCategoryQuestionPageDetail = React.lazy(() =>
  import('../containers/AdminPage/ManagementCategoryQuestionPageDetail')
);
const TrueDetailExam = React.lazy(() => import('../containers/UserPage/TrueDetailExamPage'));
const SettingExamPage = React.lazy(() => import('../containers/AdminPage/SettingExamPage'));
const ContestDetail = React.lazy(() => import('../containers/UserPage/ContestDetailPage'));
const AuditionsPage = React.lazy(() => import('../containers/UserPage/AuditionsPage'));
const ChooseFields = React.lazy(() => import('../containers/UserPage/ChooseFieldsPage'));
const ResultPage = React.lazy(() => import('../containers/UserPage/ResultPage'));
const AuditionsResultPage = React.lazy(() => import('../containers/UserPage/AuditionsResultPage'));
const ContactPage = React.lazy(() => import('../containers/UserPage/ContactPage'));
const ManagementHeaderAndFooter = React.lazy(() =>
  import('../containers/AdminPage/ManagementHeaderAndFooter')
);
const Routes = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <AuthRoute exact path={ROUTES_USER.PROFILE_PAGE} component={ProfileUserPage} />
        <AuthRoute exact path={ROUTES_USER.POLICY_PAGE} component={PolicyUserPage} />
        {/* None auth */}
        <NonAuthRoute exact path={ROUTES.RESET_PASSWORD_PAGE} component={ResetPasswordPage} />
        <NonAuthRoute
          exact
          path={ROUTES.FORGOT_PASSWORD_SUCCESS}
          component={SuccessForgotPassword}
        />
        <NonAuthRoute exact path={ROUTES.HOME} component={HomeUser} />
        <NonAuthRoute exact path={ROUTES.STUDY_FOR_TEST} component={HomePage} />
        <NonAuthRoute exact path={ROUTES.COMPETITION} component={HomePage} />
        <NonAuthRoute exact path={ROUTES.DONORS} component={HomePage} />
        <NonAuthRoute exact path={ROUTES.POLICY} component={HomePage} />
        <NonRoute exact path={ROUTES.LIST_NEWS_USER_PAGE} component={ListNewsUserPage} />
        <NonAuthRoute exact path={ROUTES.DONOR_PAGE} component={DonorPage} />
        <NonAuthRoute exact path={ROUTES.INTRODUCE} component={IntroducePageUser} />
        <NonAuthRoute exact path={ROUTES.CONTACT} component={ContactPage} />
        <NonAuthRoute exact path={ROUTES.LOGIN} component={LoginPage} />
        <NonAuthRoute exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <NonAuthRoute exact path={ROUTES.FORGOT_PASSWORD_MODAL} component={ForgotPassModal} />
        <NonRoute exact path={ROUTES_USER.HOME_PAGE} component={HomeUserPage} />
        <NonRoute exact path={ROUTES_USER.DETAIL_NEWS_USER} component={DetailNewsUserPage} />
        <NonRoute exact path={ROUTES_USER.CONTEST_RESULTS} component={ContesResults} />
        <NonRoute exact path={ROUTES_USER.CONTEST_HOME_USER} component={HomeUser} />
        {/* Auth */}
        <NonAuthRoute exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <NonAuthRoute
          exact
          path={ROUTES.FORGOT_PASSWORD_SUCCESS}
          component={SuccessForgotPassword}
        />
        {/* Auth */}
        <AuthRoute exact path={ROUTES_USER.POLICY_PAGE} component={PolicyUserPage} />
        <NonAuthRoute exact path={ROUTES_USER.CONTEST_DETAIL} component={ContestDetail} />
        <NonAuthRoute exact path={ROUTES_USER.AUDITIONS_PAGE} component={AuditionsPage} />
        <NonRoute exact path={ROUTES_USER.TRUE_DETAIL_EXAM} component={TrueDetailExam} />
        <NonAuthRoute exact path={ROUTES_USER.CHOOSE_FIELDS} component={ChooseFields} />
        <NonAuthRoute exact path={ROUTES_USER.RESULT_PAGE} component={ResultPage} />
        <NonAuthRoute
          exact
          path={ROUTES_USER.AUDITIONS_RESULT_PAGE}
          component={AuditionsResultPage}
        />
        {/* Admin */}
        <AdminRoute exact path={ROUTES_ADMIN.OVERVIEW_PAGE} component={OverviewPage} />
        <AdminRoute exact path={ROUTES_ADMIN.LIST_NEWS} component={ListNewsPage} />
        <AdminRoute exact path={ROUTES_ADMIN.ADD_LIST_NEWS} component={AddListNewsPage} />
        <AdminRoute exact path={ROUTES_ADMIN.LIST_NEWS_DETAIL} component={ListNewsPageDetail} />
        <AdminRoute exact path={ROUTES_ADMIN.EDIT_NEWS} component={EditNewsPage} />
        <AdminRoute exact path={ROUTES_ADMIN.LIST_QUESTIONS} component={ManagementQuestionPage} />
        <AdminRoute exact path={ROUTES_ADMIN.CREATE_QUESTION} component={CreateQuestionPage} />
        <AdminRoute exact path={ROUTES_ADMIN.EDIT_QUESTION} component={EditQuestionPage} />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.CATEGORIES_QUESTION}
          component={CategoriesQuestionPage}
        />
        <AdminRoute exact path={ROUTES_ADMIN.SETOF_QUESTION} component={SetOfQuestionPage} />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.MANAGEMENTSETOF_QUESTION}
          component={ManagementSetOfQuestionPage}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.LIST_COMPETITIONS}
          component={ManagementCompetitionPage}
        />{' '}
        <AdminRoute
          exact
          path={ROUTES_ADMIN.LIST_COMPETITIONS_DETAIL}
          component={ManagementCompetitionPageDetails}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.CREATE_COMPETITION}
          component={CreateCompetitionPage}
        />
        <AdminRoute exact path={ROUTES_ADMIN.EDIT_COMPETITION} component={EditCompetitionPage} />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.USER_MANAGEMENT}
          component={ManagementCandidatesPage}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.DETAIL_USER_MANAGEMENT}
          component={DetailManagementCandidatesPage}
        />
        <AdminRoute exact path={ROUTES_ADMIN.LIST_ADMINS} component={ManagementAdminPage} />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.MANAGERMENT_PERMISSION}
          component={ManagementPermissionPage}
        />
        <AdminRoute exact path={ROUTES_ADMIN.RULES} component={RulesPage} />
        <AdminRoute exact path={ROUTES_ADMIN.PRACTICE} component={PracticePage} />
        <AdminRoute exact path={ROUTES_ADMIN.RULES_PAGE} component={RulesPage} />
        <AdminRoute exact path={ROUTES_ADMIN.POLICY} component={PolicyPage} />
        <AdminRoute exact path={ROUTES_ADMIN.PARTNER} component={ManagementPartnerPage} />
        <AdminRoute exact path={ROUTES_ADMIN.NOTIFICATION_PAGE} component={NotificationPage} />
        <AdminRoute exact path={ROUTES_ADMIN.PROFILE} component={ProfilePage} />
        <AdminRoute exact path={ROUTES_ADMIN.IMPORT_QUESTION_PAGE} component={ImportQuestion} />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.MANAGEMENT_CATEGORY_QUESTION_PAGE}
          component={ManagementCategoryQuestionPage}
        />
        <AdminRoute exact path={ROUTES_ADMIN.IMPORT_QUESTION_PAGE} component={ImportQuestion} />
        <AdminRoute exact path={ROUTES_ADMIN.ADD_COMPETITION_PAGE} component={AddCompetition} />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.DEPARTMENT_QUESTION_PAGE_DETAIL}
          component={DepartmentQuestionPageDetail}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.MANAGEMENT_CATEGORY_QUESTION_PAGE_DETAIL}
          component={CategoriesQuestionPageDetail}
        />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.MANAGEMENT_CATEGORY_QUESTION_PAGE_DETAIL}
          component={ManagementCategoryQuestionPageDetail}
        />
        <AdminRoute exact path={ROUTES_ADMIN.SETTING_EXAM} component={SettingExamPage} />
        <AdminRoute exact path={ROUTES_ADMIN.INTRODUCE_PAGE} component={IntroducePage} />
        <AdminRoute
          exact
          path={ROUTES_ADMIN.MANAGEMENT_HEADER_AND_FOOTER}
          component={ManagementHeaderAndFooter}
        />
      </Switch>
    </ScrollToTop>
  </BrowserRouter>
);

export default Routes;
