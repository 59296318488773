const preRoute = '';
const preAdmin = '/admin';

export const ROUTES = {
  HOME: '/',
  LOGIN: `${preRoute}/login`,
  FORGOT_PASSWORD: `${preRoute}/forgot-password`,
  FORGOT_PASSWORD_SUCCESS: `${preRoute}/forgot-password-success`,
  STUDY_FOR_TEST: `${preRoute}/study-for-test`,
  COMPETITION: `${preRoute}/competition`,
  DONORS: `${preRoute}/donors`,
  POLICY: `${preRoute}/policy`,
  LIST_NEWS_USER_PAGE: `${preRoute}/list-news-user-page`,
  DONOR_PAGE: `${preRoute}/donor`,
  INTRODUCE: `${preRoute}/introduce`,
  CONTACT: `${preRoute}/contact`,
  FORGOT_PASSWORD_MODAL: `${preRoute}/forgot-password-modal`,
  RESET_PASSWORD_PAGE: `${preRoute}/reset-password-page`,
};

export const ROUTES_ADMIN = {
  RULES_PAGE: `${preAdmin}/rules`,
  OVERVIEW_PAGE: `${preAdmin}/overview`,
  LIST_QUESTIONS: `${preAdmin}/list-questions`,
  CREATE_QUESTION: `${preAdmin}/create-question/:id`,
  EDIT_QUESTION: `${preAdmin}/update-question/:id`,
  CATEGORIES_QUESTION: `${preAdmin}/categories-question`,
  LIST_COMPETITIONS: `${preAdmin}/list-competitions`,
  LIST_COMPETITIONS_DETAIL: `${preAdmin}/list-competitions-details/:id`,
  CREATE_COMPETITION: `${preAdmin}/create-competition`,
  EDIT_COMPETITION: `${preAdmin}/update-competition/:id`,
  USER_MANAGEMENT: `${preAdmin}/management-user`,
  DETAIL_USER_MANAGEMENT: `${preAdmin}/detail-management-user/:id`,
  LIST_ADMINS: `${preAdmin}/list-admins`,
  MANAGERMENT_PERMISSION: `${preAdmin}/management-permission`,
  SETTINGS: `${preAdmin}/setting`,
  POLICY: `${preAdmin}/policy`,
  RULES: `${preAdmin}/rules`,
  PRACTICE: `${preAdmin}/practice`,
  PARTNER: `${preAdmin}/partner`,
  NOTIFICATION_PAGE: `${preAdmin}/notification`,
  PROFILE: `${preAdmin}/profile`,
  MANAGEMENT_CATEGORY_QUESTION_PAGE: `${preAdmin}/management-category-question-page`,
  IMPORT_QUESTION_PAGE: `${preAdmin}/import-question-page`,
  ADD_COMPETITION_PAGE: `${preAdmin}/add-competition-page`,
  SETOF_QUESTION: `${preAdmin}/set-of-question-page`,
  MANAGEMENTSETOF_QUESTION: `${preAdmin}/management-set-of-question-page`,
  DEPARTMENT_QUESTION_PAGE_DETAIL: `${preAdmin}/department-question-page/:id`,
  SETTING_EXAM: `${preAdmin}/setting-exam-page`,
  MANAGEMENT_CATEGORY_QUESTION_PAGE_DETAIL: `${preAdmin}/category-question-page/:id`,
  LIST_NEWS: `${preAdmin}/list-news-page`,
  ADD_LIST_NEWS: `${preAdmin}/add-list-news`,
  EDIT_NEWS: `${preAdmin}/edit-news/:id`,
  LIST_NEWS_DETAIL: `${preAdmin}/list-news-detail/:id`,
  INTRODUCE_PAGE: `${preAdmin}/introduce`,
  MANAGEMENT_HEADER_AND_FOOTER: `${preAdmin}/management-header-and-footer`,
};

export const ROUTES_USER = {
  NOTIFICATION_PAGE: `${preRoute}/notification`,
  POLICY_PAGE: `${preRoute}/policy`,
  PROFILE_PAGE: `${preRoute}/profile`,
  CONTEST_DETAIL: `${preRoute}/contest-detail-page/:id`,
  TRUE_DETAIL_EXAM: `${preRoute}/true-exam/:id/:idR`,
  HOME_PAGE: `${preRoute}/home-user`,
  CONTEST_RESULTS: `${preRoute}/contest-results/:id`,
  CHOOSE_FIELDS: `${preRoute}/choose-fields-page`,
  RESULT_PAGE: `${preRoute}/result-page`,
  CONTEST_HOME_USER: `${preRoute}/home`,
  DETAIL_NEWS_USER: `${preRoute}/detail-news-user/:id`,
  AUDITIONS_PAGE: `${preRoute}/auditions-page`,
  AUDITIONS_RESULT_PAGE: `${preRoute}/auditions-result-page`,
};
