import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getIntroduceApi } from 'services/apis/other/introduce';

export const getIntroduce = createAsyncThunk(
  'introduce/GET_LIST',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getIntroduceApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

const initialState = {
  listIntroduce: {},
  paginate: {},
  details: {},
  totalAmount: {},
  loading: false,
  error: {},
};

const introduceSlice = createSlice({
  name: 'donor',
  initialState,

  extraReducers: {
    [getIntroduce.pending]: (state) => {
      state.loading = true;
    },
    [getIntroduce.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getIntroduce.fulfilled]: (state, action) => {
      state.listIntroduce = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
  },
});
const { reducer: introduceReducer } = introduceSlice;
export default introduceReducer;
