import React, { useState, useEffect } from 'react';
import { Avatar, Button, Menu } from 'antd';
import { ReactSVG as SVG } from 'react-svg';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'components/commons/Typo';

import { getListNotification } from 'store/user/overviewSlice';

import { logOut } from 'store/other/authSlice';
import { ROUTES_ADMIN } from 'routes/constants';

import logo from 'assets/icons/logo.svg';
import logout from 'assets/icons/exit_to_app.svg';
import avatar from 'assets/icons/avatar.svg';
import bell from 'assets/icons/bell.svg';
import back from 'assets/icons/back.svg';
import menu from 'assets/icons/menu.svg';
import document from 'assets/icons/document.svg';
import useTranslation from '../../hooks/useTranslation';

import * as S from './styled';

import { data } from './mock-data';

export default function Layout({ children }) {
  const { t } = useTranslation('layout');

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [active, setActive] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  const info = useSelector((state) => state.authReducer.currentUser);
  const userInfo = useSelector((state) => state.userReducer?.userInfo);

  useEffect(() => {
    dispatch(getListNotification());
  }, [dispatch]);

  useEffect(() => {
    setActive(location?.pathname);
  }, [location?.pathname]);

  const handleLogOutAPI = async () => {
    dispatch(logOut());
  };

  const [initWidth, setInitWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (initWidth < 992) {
      setCollapsed(true);
    }
  }, [initWidth]);

  const changeWidth = (e) => {
    setInitWidth(window.innerWidth);
  };

  window.addEventListener('resize', changeWidth);

  let pageTitle = t('managerCompetition');
  data.every((item) => {
    if (item.link === history.location.pathname) {
      pageTitle = t(item.title);
      return false;
    }
    if (item.menus?.length > 0) {
      item.menus.every((subItem) => {
        if (subItem.link === history.location.pathname) {
          pageTitle = t(subItem.title);
          return false;
        }
        return true;
      });
    }
    return true;
  });

  return (
    <S.Layout>
      <S.Layout>
        {!collapsed ? (
          <S.Sd
            width={250}
            className="site-layout-background"
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            trigger={null}
            showTab={collapsed}
            style={{ background: '#ffffff' }}
          >
            <S.HDSB>
              {!collapsed && (
                <S.DFlex>
                  <Link to={ROUTES_ADMIN.OVERVIEW_PAGE}>
                    <img src={logo} alt="logo" />
                  </Link>
                </S.DFlex>
              )}
              <div
                style={{
                  position: 'absolute',
                  right: '20px',
                  top: '25px',
                  cursor: 'pointer',
                }}
              >
                <SVG onClick={() => setCollapsed(!collapsed)} className="mainColor" src={menu} />
              </div>
            </S.HDSB>
            <S.WrapMenu>
              <Menu mode="inline" inlineCollapsed={collapsed} defaultSelectedKeys="P1">
                {data?.map((item) => (
                  <>
                    {item?.menus?.length > 0 ? (
                      <Menu.SubMenu
                        key={item?.id}
                        icon={<SVG className="iconMenu" src={item?.icon} />}
                        title={t(item?.title)}
                      >
                        {item?.menus?.map((k) => (
                          <Menu.Item
                            icon={<SVG className="iconMenu" src={k?.icon} />}
                            key={k?.id}
                            onClick={() => {
                              history.push(k?.link);
                              if (window.screen.width < 767) {
                                setCollapsed(!collapsed);
                              }
                            }}
                          >
                            {t(k?.title)}
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>
                    ) : (
                      <Menu.Item
                        key={item?.id}
                        icon={<SVG className="iconMenu" src={item?.icon} />}
                        onClick={() => {
                          history.push(item?.link);
                          if (window.screen.width < 767) {
                            setCollapsed(!collapsed);
                          }
                        }}
                      >
                        {t(item?.title)}
                      </Menu.Item>
                    )}
                  </>
                ))}
              </Menu>
            </S.WrapMenu>
            <S.WrapLogOut>
              <S.LogOut onClick={() => handleLogOutAPI()}>
                <img src={logout} alt="" />
                {t('logOut')}
              </S.LogOut>
            </S.WrapLogOut>
          </S.Sd>
        ) : null}
        <S.Layout>
          <S.Hd className={`header ${collapsed && 'menu-collapsed'}`}>
            <S.DFlex>
              {collapsed && (
                <div>
                  <SVG onClick={() => setCollapsed(!collapsed)} className="mainColor" src={menu} />
                </div>
              )}
              <Button type="text" onClick={() => history.goBack()} className="button-back">
                <SVG src={back} />
              </Button>
              {initWidth > 767 && <div>{pageTitle}</div>}
            </S.DFlex>
            <S.DFlex style={{ alignItems: 'center', gap: '24px' }}>
              {/* <Button
                onClick={() => history.push(ROUTES_ADMIN.CREATE_COMPETITION)}
                className="button-create-competition"
              >
                <SVG className="iconButton" src={document} />
                {initWidth > 767 && t('createCompetition')}
              </Button> */}
              <S.WrapBell onClick={() => history.push(ROUTES_ADMIN.NOTIFICATION_PAGE)}>
                <SVG src={bell} />
                <div className="noti-qty"></div>
                <div className="qty">0</div>
              </S.WrapBell>
              <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                <Link to={ROUTES_ADMIN.PROFILE}>
                  <Avatar size={40} src={userInfo?.anhThe || avatar}></Avatar>
                </Link>
                <Text isBold style={{ color: '#303030', textTransform: 'capitalize' }}>
                  {info.name}
                </Text>
              </div>
            </S.DFlex>
          </S.Hd>
          <S.Contents>{children}</S.Contents>
        </S.Layout>
      </S.Layout>
    </S.Layout>
  );
}
