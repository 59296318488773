export default {
  wrongFormat: 'Sai định dạng',
  image: 'Hình ảnh',
  maxImage: ' Tối đa 1 ảnh, định',
  type: 'dạng jpg, png',
  moreAnswers: 'Thêm chi nhánh',
  headQuarters: 'Trụ sở chính',
  branchOne: 'Chi nhánh ',
  branchTwo: 'Chi nhánh 2',
  branchThree: 'Chi nhánh 3',
  header: 'Header',
  titleHeader: 'Logo sẽ được thay đổi cho cả header và footer',
  logoTitle: 'Logo đơn vị liên kết',
  logoTitleRight: 'Logo Phong & Partner',
  footer: 'Footer',
  introduce: 'Lời giới thiệu',
  inputValueOne:
    'Phong & Partners cung cấp tất cả dịch vụ pháp lý với chất lượng cao nhất - là kết quả từ sự chuyên nghiệp, chuyên tâm và sự gắn kết, hỗ trợ hiệu quả của toàn hệ thống vì lợi ích cao nhất của khách hàng.',
  lawerTitle: 'Văn phòng Luật sư Phong & Partner',
  inputIntroduce: 'Văn phòng luật sư Phong & Partners',
  name: 'Tên',
  importName: 'Nhập tên',
  delete: 'Xóa',
  address: 'Địa chỉ',
  importAddress: 'Nhập địa chỉ',
  phone: 'Số điện thoại',
  importPhone: 'Nhập số điện thoại',
  unitTitle: 'Đơn vị liên kết',
  inputValueTwo: 'Khoa Luật, Trường ĐH Kinh tế - Đại học Đà Nẵng',
  email: 'Email',
  importEmail: 'Nhập email',
  website: 'Website',
  importWebsite: 'Nhập website',
  save: 'Lưu',
  socical: 'Mạng xã hội',
  fb: 'Facebook',
  tw: 'Twitter',
  yt: 'Youtube',
  importFb: 'Nhập liên kết Facebook',
  importTw: 'Nhập liên kết Twitter',
  importYt: 'Nhập liên kết Youtube',
};
