import { getListContestEnteredApi } from 'services/apis/user/profile';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListContestEntered = createAsyncThunk(
  'contest/GET_CONTEST_ENTERED_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListContestEnteredApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listContestEntered: [],
  loading: false,
  paginate: {},
  error: {},
};

const userContestEnteredSlice = createSlice({
  name: 'userContestEntered',
  initialState,

  extraReducers: {
    [getListContestEntered.pending]: (state) => {
      state.loading = true;
    },
    [getListContestEntered.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListContestEntered.fulfilled]: (state, action) => {
      state.listContestEntered = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: userContestEnteredReducer } = userContestEnteredSlice;
export default userContestEnteredReducer;
