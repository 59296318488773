import * as yup from 'yup';

const Schema = yup.object().shape({
  user_code: yup.string().required('Vui lòng nhập mã nhân viên'),
  // .matches(REGEX.PHONE, 'SĐT không đúng định dạng !'),
  password: yup.string().trim().required('Vui lòng nhập mật khẩu'),
  // .matches(REGEX.pass, 'phải tối thiểu 8 ký tự (bao gồm chữ hoa, chữ thường, chữ số)'),
  // email: yup.string().email('Email không hợp lệ').max(255).required('Vui lòng nhập email')
});
export const emailSchema = yup.object({
  email: yup.string().email('Email không hợp lệ').required('Vui lòng nhập email'),
  user_code: yup.string().required('Vui lòng nhập mã nhân viên'),
});
export const resetpassV2Schema = yup.object({
  password: yup
    .string()
    .required('Vui lòng nhập mật khẩu mới')
    .min(8, 'Mật khẩu có ít nhất 8 kí tự')
    .max(20, 'Mật khẩu có tối đa 20 kí tự'),
  password_confirmation: yup
    .string()
    .required('Vui lòng xác nhận mật khẩu mới')
    .oneOf([yup.ref('password'), null], 'Mật khẩu xác nhận không khớp'),
});
export default Schema;
