import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getListDonorApi } from 'services/apis/user/donor';

export const getListDonor = createAsyncThunk(
  'donor/GET_LIST',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getListDonorApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

const initialState = {
  listDonor: [],
  paginate: {},
  details: {},
  totalAmount: {},
  loading: false,
  error: {},
};

const donorSlice = createSlice({
  name: 'donor',
  initialState,

  extraReducers: {
    [getListDonor.pending]: (state) => {
      state.loading = true;
    },
    [getListDonor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListDonor.fulfilled]: (state, action) => {
      state.listDonor = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
  },
});
const { reducer: donorReducer } = donorSlice;
export default donorReducer;
