export default {
  name: 'Họ và tên',
  birthDay: 'Ngày tháng năm sinh',
  email: 'Email',
  phone: 'Số điện thoại',
  address: 'Địa chỉ',
  save: 'đổi mật khẩu',
  avatar: 'Ảnh đại diện',
  upload: 'upload ảnh',
  note: 'Định dạng .jpg, .jpeg, .png dung lượng thấp hơn 300 KB với kích thước tối thiểu 300x300 px',
  infomation: 'Thông tin tài khoản',
  ressetPass: 'Thay đổi mật khẩu',
  joined: 'Cuộc thi đã tham gia',
  currentPassword: 'Mật khẩu hiện tại*',
  newPass: 'Mật khẩu mới*',
  confirm: 'Xác nhận lại mật khẩu*',
  logOut: 'Đăng xuất',
  information: 'Thông tin tài khoản',
};
