import { getRolesApi } from 'services/apis/admin/role';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListRole = createAsyncThunk(
  'role/GET_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getRolesApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listRole: [],
  paginate: {},
  loading: false,
  error: {},
};

const roleSlice = createSlice({
  name: 'role',
  initialState,

  extraReducers: {
    [getListRole.pending]: (state) => {
      state.loading = true;
    },
    [getListRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListRole.fulfilled]: (state, action) => {
      state.listRole = action.payload.data;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: roleReducer } = roleSlice;
export default roleReducer;
