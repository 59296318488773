export default {
  nameNews: 'Tên tin tức',
  placeholderNameNews: 'Nhập tên tin tức',
  avatarImage: 'Ảnh đại diện',
  uploadImage: 'Upload ảnh',
  desUploadImage: 'Tối đa 1 ảnh, định dạng jpg, png',
  contentNews: 'Nội dung tin tức',
  deleteAll: 'Xóa tất cả',
  hotNews: 'Tin nổi bật',
  homeNews: 'Hiển thị trên trang chủ',
  createNews: 'Tạo tin tức',
};
