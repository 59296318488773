export default {
  discount: 'Giảm giá',
  answer: 'Mua 1 tặng 1',
  answerTwo: 'Khuyến mãi',
  answerEnd: 'Tất cả phương án trên',
  contentTitle: 'Làm thế nào để bán được 1000 gói mè?',
  comeBack: 'Quay lại',
  questionNext: 'Câu tiếp theo',
  title: 'Câu',
  result: 'Kết quả',
  youComplete: 'Bạn đã hoàn thành bài luyện thi',
  youRight: 'Bạn đã trả lời đúng',
  questionResult: '8/10',
  return: 'Làm lại',
  logOut: 'Thoát',
  seeResult: 'Xem đáp án',
  complete: 'Hoàn thành',
  timeOut: 'đã hết thời gian. không thể tiếp tục làm bài luyện thi',
};
