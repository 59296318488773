export default {
  managementQuestion: 'Quản lý câu hỏi',
  dashboard: 'Dashboard',
  listQuestions: 'Tất cả câu hỏi',
  categoriesQuestion: 'Lĩnh vực câu hỏi',
  setOfQuestion: 'Bộ câu hỏi',
  managerUser: 'Quản lý thí sinh',
  managementCompetition: 'Quản lý cuộc thi',
  listCompetitions: 'Tất cả cuộc thi',
  settings: 'Cài đặt chung',
  createCompetition: 'Tạo cuộc thi mới',
  managementAdmin: 'Quản lý Admin',
  listNews: 'Tất cả tin tức',
  managementNews: 'Quản lý tin tức',
  listAdmins: 'Danh sách Admin',
  managementPermission: 'Phân quyền',
  managementUser: 'Quản lý thí sinh',
  policy: 'Chính sách sử dụng',
  settingExam: 'Thi thử',
  listPartner: 'Nhà tài trợ và đối tác',
  rules: 'Nội quy',
  practice: 'Luyện thi',
  logOut: 'ĐĂNG XUẤT',
  managerCompetition: 'Quản lý cuộc thi',
  headerAndFooter: 'Header và footer',
  allExaminee: 'Tất cả thí sinh',
  introduce: 'Giới thiệu',
};
