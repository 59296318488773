import { ROUTES, ROUTES_USER } from 'routes/constants';

export const data = [
  {
    id: 'P1',
    title: 'home',
    link: ROUTES_USER.CONTEST_HOME_USER,
  },
  {
    id: 'P2',
    title: 'introduce',
    link: ROUTES.INTRODUCE,
  },
  {
    id: 'P3',
    title: 'donors',
    link: ROUTES.DONOR_PAGE,
  },
  {
    id: 'P4',
    title: 'studyForTest',
    link: ROUTES_USER.CHOOSE_FIELDS,
  },
  {
    id: 'P5',
    title: 'competition',
    link: ROUTES_USER.HOME_PAGE,
  },
  {
    id: 'P6',
    title: 'new',
    link: ROUTES.LIST_NEWS_USER_PAGE,
  },
  {
    id: 'P7',
    title: 'contact',
    link: ROUTES.CONTACT,
  },
];
