import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getListHotNewsUserApi, getLatestNewsApi, getReadNewsApi, getNewsUserDetailApi } from 'services/apis/user/newsUser';

export const getHotNewsUser = createAsyncThunk(
  'newsUser/GET_LIST',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getListHotNewsUserApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const getLatestNewsUser = createAsyncThunk(
  'newsUser/GET_LIST_LATEST',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getLatestNewsApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const getReadNewsUser = createAsyncThunk(
  'newsUser/GET_LIST_READ',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getReadNewsApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

export const getNewsUserDetail = createAsyncThunk(
  'newsUser/GET_NEWS_USER_DETAIL',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getNewsUserDetailApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.data);
    }
  }
);

const initialState = {
  listUserNews: [],
  listLatestNews: [],
  listReadNews: [],
  listNewsUserDetail: [],
  detailNews: {},
  paginate: {},
  details: {},
  totalAmount: {},
  loading: false,
  error: {},
};

const newsUserSlice = createSlice({
  name: 'news',
  initialState,

  extraReducers: {
    [getHotNewsUser.pending]: (state) => {
      state.loading = true;
    },
    [getHotNewsUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getHotNewsUser.fulfilled]: (state, action) => {
      state.listUserNews = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
    [getLatestNewsUser.pending]: (state) => {
      state.loading = true;
    },
    [getLatestNewsUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getLatestNewsUser.fulfilled]: (state, action) => {
      state.listLatestNews = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
    [getReadNewsUser.pending]: (state) => {
      state.loading = true;
    },
    [getReadNewsUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getReadNewsUser.fulfilled]: (state, action) => {
      state.listReadNews = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
    [getNewsUserDetail.pending]: (state) => {
      state.loading = true;
    },
    [getNewsUserDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getNewsUserDetail.fulfilled]: (state, action) => {

      state.listNewsUserDetail = action?.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: newsUserReducer } = newsUserSlice;
export default newsUserReducer;
