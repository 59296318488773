import React from 'react';
import styled from 'styled-components';

import { Spin, Space } from 'antd';

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = () => (
  <SpinnerWrapper>
    <Space>
      <Spin size="large" />
    </Space>
  </SpinnerWrapper>
);

export default Loading;
