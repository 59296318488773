import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getListCompetitionApi,
  getDetailsCompetitionApi,
  getResultCompetitionApi,
  getListCreatorApi,
} from 'services/apis/admin/competition';

export const getListCompetition = createAsyncThunk(
  'competition/GET_LIST_COMPETITION',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getListCompetitionApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getListCreator = createAsyncThunk(
  'competition/GET_LIST_CREATOR',
  async (data, { rejectWithValue }) => {
    try {
      const response = await getListCreatorApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getListDetailsCompetition = createAsyncThunk(
  'competition/GET_LIST_DETAILS_COMPETITION',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getDetailsCompetitionApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getListResultCompetition = createAsyncThunk(
  'competition/GET_LIST_RESULT_COMPETITION',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getResultCompetitionApi(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listCompetition: [],
  paginate: {},
  details: {},
  totalAmount: {},
  listDetailsCompetition: {},
  listResultCompetition: [],
  listCreator: [],
  loading: false,
  error: {},
};

const competitionSlice = createSlice({
  name: 'competition',
  initialState,

  extraReducers: {
    [getListCompetition.pending]: (state) => {
      state.loading = true;
    },
    [getListCompetition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCompetition.fulfilled]: (state, action) => {
      state.listCompetition = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },

    [getListDetailsCompetition.pending]: (state) => {
      state.loading = true;
    },
    [getListDetailsCompetition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListDetailsCompetition.fulfilled]: (state, action) => {
      state.listDetailsCompetition = action.payload?.data || {};

      state.loading = false;
      state.error = {};
    },

    [getListResultCompetition.pending]: (state) => {
      state.loading = true;
    },
    [getListResultCompetition.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListResultCompetition.fulfilled]: (state, action) => {
      state.listResultCompetition = action.payload.data;
      state.loading = false;
      state.error = {};
    },
    [getListCreator.pending]: (state) => {
      state.loading = true;
    },
    [getListCreator.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCreator.fulfilled]: (state, action) => {
      state.listCreator = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: competitionReducer } = competitionSlice;
export default competitionReducer;
