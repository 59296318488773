import styled from 'styled-components';
import media from 'styles/breakpoints';

export const Wrapper = styled.div`
  .text-center {
    background: #f6c92b;
  }
  .bg-light {
    background: #014c84 !important;
  }
  .text-dark {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #afd7f4 !important;
  }
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin: 0 0 12px 0;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #afd7f4;
  }
  .link-footer {
    display: flex;
    gap: 0 6px;
    align-items: center;
    margin: 12px 0 0 0;
  }
  .link-footer-image {
    display: flex;
    gap: 0 16px;
    margin-top: 12px;
  }
  .address {
    border-left: 1px solid #0c5a94;
  }
  .image-logo {
    margin-bottom: 28px;
    width: 50%;
    height: 66px;
  }
  .image-logo1 {
    width: 25%;
    border-radius: 50%;
    height: 66px;
    margin-right: 8px;
    object-fit: cover;
  }
  .follow {
    margin: 16px 0 12px 0;
  }
  .text-dark-right {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #afd7f4 !important;
  }
  .text-uppercase {
    margin-bottom: 12px;
  }
  .footer-column-3 {
    margin: 4px 0;
  }
  .lawer {
    margin-bottom: 12px !important ;
  }
  .title-detail {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #afd7f4;
  }
  ${media.tablet`
  .link-footer{
      justify-content: center;
      &-image{
        justify-content: center;
      }
  }
  `}
  .logo-all {
    ${media.tablet`
    justify-content:center;
  `}
  }
`;
