import { css } from 'styled-components';

const utility = css`
  :root {
    --bs-primary: #d72323;
  }
  .container {
    padding: 0;
    @media (max-width: 576px) {
      width: 100%;
    }
    @media (min-width: 576px) {
      width: 540px;
    }
    @media (min-width: 768px) {
      width: 720px;
    }
    @media (min-width: 992px) {
      width: 960px;
    }
    @media (min-width: 1200px) {
      width: 1140px;
    }
    @media (min-width: 1400px) {
      width: 1170px;
    }
  }
  .cursorPointer {
    cursor: pointer;
  }

  /* DatePicker */
  .ant-picker {
    border-radius: ${({ theme }) => theme.borderRadius.base};
    padding: 12px;
    box-shadow: none;
    width: 100%;
  }
  .ant-picker:hover,
  .ant-picker-focused {
    border-color: ${({ theme }) => theme.colors.primaryButtonColor};
  }
  .ant-picker-panel-container {
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    padding: 16px;
  }
  .ant-picker-header-view button:hover {
    color: ${({ theme }) => theme.colors.primaryButtonColor};
  }
  .ant-picker-content th,
  .ant-picker-content td {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
  .ant-picker-date-panel .ant-picker-content th {
    color: ${({ theme }) => theme.colors.gray3};
  }
  .ant-picker-cell {
    padding: 8px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primaryButtonColor};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding: 0px 4px;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
  }
  .ant-picker-footer {
    /* display: none; */
  }
  .ant-picker.ant-picker-disabled {
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
  }
  .ant-picker-today-btn {
    color: ${({ theme }) => theme.colors.primaryButtonColor};

    &:hover {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  /* TimePicker */
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: ${({ theme }) => theme.colors.primaryButtonColor};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    color: ${({ theme }) => theme.colors.white};
  }
  .ant-picker-now > a {
    color: ${({ theme }) => theme.colors.primaryButtonColor};
  }
  .ant-btn-primary {
    border-color: ${({ theme }) => theme.colors.primaryButtonColor};
    background: ${({ theme }) => theme.colors.primaryButtonColor};

    &:hover,
    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.colors.red} !important;
      background: ${({ theme }) => theme.colors.red} !important;
      color: #fff !important;
    }
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: ${({ theme }) => theme.colors.primaryButtonColor};
    border-color: ${({ theme }) => theme.colors.primaryButtonColor};
  }

  .ant-switch-checked {
    background: #d72323;
  }

  .ant-spin-dot-item {
    background-color: ${({ theme }) => theme.colors.mainColor};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: #25a519 !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: #25a519;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: #25a519;
  }

  .isAnswerUser .ant-radio-checked .ant-radio-inner {
    border-color: #eb222c !important ;
  }

  .isAnswerUser .ant-radio-checked .ant-radio-inner:after {
    background-color: #eb222c;
  }

  .isAnswerUser .ant-radio:hover .ant-radio-inner {
    border-color: #eb222c;
  }

  .ant-pagination {
    text-align: right;
  }
  .ant-pagination .ant-pagination-item,
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next {
    color: #333;
  }
  .ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination .ant-pagination-item:hover,
  .ant-pagination .ant-pagination-item.ant-pagination-item-active {
    background: #f6c92b;
    border-radius: 5px;
    border-color: transparent;
  }
  .ant-pagination .anticon svg {
    margin-top: -5px;
  }

  // TREE SELECT
  .e-list-item {
    margin: 8px 0px;
  }

  .e-text-content {
    padding: 10px 0px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
  }
  .ant-form-item {
    margin: 0 0 16px;
  }
  .ant-menu {
    .ant-menu-item,
    .ant-menu-submenu {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.textColor};
      background: transparent;
      .ant-menu-sub.ant-menu-inline {
        background: transparent;
      }
      &:hover,
      &.ant-menu-item-selected {
        color: ${({ theme }) => theme.colors.textColor};
        background: #fafafa;
      }
      &::after {
        left: 0;
        right: auto;
        border-color: ${({ theme }) => theme.colors.mainColor};
      }
    }
    .ant-menu-item,
    .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-submenu .ant-menu-item {
      height: 48px;
      line-height: 48px;
      margin: 0 !important;
      color: ${({ theme }) => theme.colors.textColor};
    }
    .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: ${({ theme }) => theme.colors.textColor};
    }
    .ant-menu-submenu .ant-menu-item {
      font-weight: 400;
      padding-left: 35px !important;
    }
  }

  .ck-editor {
    ul > li {
      list-style: circle;
    }

    ol > li {
      list-style: decimal;
    }

    ul,
    ol {
      padding-left: 2rem;
    }
  }
`;
export default utility;
