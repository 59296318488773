import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import {resetPassFirst} from 'services/apis/other/auth';
import { getMe } from 'store/other/authSlice';

import Input from 'components/Form/InputField';
import Button from 'components/commons/Button';

import iconPass from '../LoginPage/images/pass.svg';
import logo from '../LoginPage/images/logo.svg';
import eye from '../LoginPage/images/eye.svg';
import iconShow from '../LoginPage/images/iconShow.svg';
import * as S from '../LoginPage/styled';

function FirstLogin() {
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const schema = yup.object({
    password: yup
      .string()
      .required('Vui lòng nhập mật khẩu mới')
      .min(8, 'Mật khẩu có ít nhất 8 kí tự')
      .max(26, 'Mật khẩu có tối đa 26 kí tự'),
    password_confirmation: yup
      .string()
      .required('Vui lòng nhập lại mật khẩu mới')
      .oneOf([yup.ref('password'), null], 'Mật khẩu nhập lại không khớp'),
  });
  const handleChangePass = async (data) => {
    const res = await resetPassFirst(data);
    if (res.status === 200) {
      dispatch(getMe());
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });
  return (
    <S.Wrapper>
      <S.Header>
        <img src={logo} alt=" " />
        <h1>HỆ THỐNG ĐÀO TẠO NỘI BỘ</h1>
      </S.Header>
      <S.Form onSubmit={handleSubmit(handleChangePass)}>
        <S.Title>ĐỔI MẬT KHẨU</S.Title>
        <S.Label>Mật khẩu mới</S.Label>
        <Input
          $height="52px"
          icon={iconPass}
          iconPass={showPass ? iconShow : eye}
          placeholder="Nhập mật khẩu mới"
          name="password"
          type={showPass ? 'text' : 'password'}
          set_show={setShowPass}
          show={showPass}
          register={register}
          error={errors?.password?.message}
        />
        <S.Label>Nhập lại mật khẩu mới</S.Label>
        <Input
          $height="52px"
          icon={iconPass}
          iconPass={showConfirmPass ? iconShow : eye}
          placeholder="Nhập lại mật khẩu mới"
          name="password_confirmation"
          type={showConfirmPass ? 'text' : 'password'}
          set_show={setShowConfirmPass}
          show={showConfirmPass}
          register={register}
          error={errors?.password_confirmation?.message}
        />
        <Button type="submit" width="100%" size="lg" style={{ marginTop: '16px' }}>
          CẬP NHẬT
        </Button>
      </S.Form>
    </S.Wrapper>
  );
}

export default FirstLogin;
