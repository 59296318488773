import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getListNewsApi,
  createNewsApi,
  detailNewsApi,
  updateNewsApi,
} from 'services/apis/admin/news';

export const getListNews = createAsyncThunk('news/GET_LIST', async (data, { rejectWithValue }) => {
  try {
    const response = await getListNewsApi(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error?.data);
  }
});
export const updateNews = createAsyncThunk('news/UP_DATE', async (data, { rejectWithValue }) => {
  try {
    const response = await updateNewsApi(data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error?.data);
  }
});

export const addNews = createAsyncThunk('news/ADD_NEWS', async (data, { rejectWithValue }) => {
  try {
    const response = await createNewsApi(data);
    return response?.data;
  } catch (error) {
    return rejectWithValue(error?.data);
  }
});

export const getdetailNews = createAsyncThunk(
  'news/DETAIL_NEWS',
  async (data, { rejectWithValue }) => {
    try {
      const response = await detailNewsApi(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
const initialState = {
  listNews: [],
  addNews: {},
  detailNews: {},
  updateNewsList: {},
  paginate: {},
  details: {},
  totalAmount: {},
  loading: false,
  error: {},
};

const newsSlice = createSlice({
  name: 'news',
  initialState,

  extraReducers: {
    [getListNews.pending]: (state) => {
      state.loading = true;
    },
    [getListNews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListNews.fulfilled]: (state, action) => {
      state.listNews = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
    [addNews.pending]: (state) => {
      state.loading = true;
    },
    [addNews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [addNews.fulfilled]: (state, action) => {
      state.addNews = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
    [getdetailNews.pending]: (state) => {
      state.loading = true;
    },
    [getdetailNews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getdetailNews.fulfilled]: (state, action) => {
      state.detailNews = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
    [updateNews.pending]: (state) => {
      state.loading = true;
    },
    [updateNews.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [updateNews.fulfilled]: (state, action) => {
      state.updateNewsList = action.payload?.data;
      state.paginate = action.payload?.paginate;
      state.totalAmount = action.payload?.total_amount;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: newsReducer } = newsSlice;
export default newsReducer;
