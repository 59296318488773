export default {
  textTop: 'Cuộc thi "Tìm hiểu Pháp luật trực tuyến" 2021',
  round1: 'VÒNG 1',
  round2: 'VÒNG 2',
  round3: 'VÒNG 3',
  timeRound: 'Từ 25/5 đến 30/5',
  buttonTest: 'Thi Thử',
  buttonJoin: 'Vào Thi',
  happened: 'ĐÃ DIỄN RA',
  happenning: 'ĐANG DIỄN RA',
  neverHappened: 'CHƯA DIỄN RA',
  ruleContest: 'Nội Quy Cuộc Thi',
  inforContact: 'Nhập Nội Quy Liên Hệ',
  labelName: 'Họ và Tên * ',
  labelEmail: 'Email * ',
  labelSDT: 'Số điện thoại * ',
  placeholderName: 'Nhập họ và tên',
  placeholderEmail: 'Nhập email',
  placeholderSDT: 'Nhập số điên thoại',
  inforComf: 'Thông tin liên hệ của bạn sẽ được dùng làm tài khoản đăng nhập.',
  inforComfEmail: 'Lưu ý: Điền đúng thông tin để nhận được email.',
  takeExam: 'VÀO THI',
  to: 'đến',
  notifiLogin: 'yêu cầu đăng nhập',
  nonContest: 'không được thi lại',
  noContest: 'không thể thi',
  notifiLoginText: 'Vui lòng đăng nhập để tham gia vòng thi này',
  nonContestText: 'Bài làm của bạn đã được lưu. Bạn không thể thi lại',
  noContestText: 'Bạn không vượt qua vòng nên không thể tiếp tục vào thi ',
  login: 'đăng nhập',
  viewResu: 'xem kết quả',
  understood: 'đã hiểu',
};
