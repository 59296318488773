import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'routes/constants';

import useTranslation from 'hooks/useTranslation.js';

import PrimaryButton from 'components/commons/Button';

import logo from '../Image/logo.svg';

import * as S from './styled';

function SuccessForgotPassword() {
  const { t } = useTranslation('successForgotPasswordPage');

  useEffect(() => {
    notifyMe();
  }, []);

  const notifyMe = () => {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    } else if (Notification?.permission === 'granted') {
      // If it's okay let's create a notification
    } else {
      Notification?.requestPermission();
    }
  };
  const history = useHistory();

  const handleClick = () => {
    history.push(ROUTES.LOGIN);
  };
  return (
    <S.Wrapper>
      <S.Header>
        <img src={logo} alt="logo" />
      </S.Header>
      <S.Form>
        <S.Title>{t('success')}</S.Title>
        <S.Text>{t('newPassSendYourMail')}</S.Text>
        <PrimaryButton onClick={handleClick} width="100%">
          {t('login')}
        </PrimaryButton>
      </S.Form>
    </S.Wrapper>
  );
}

export default SuccessForgotPassword;
