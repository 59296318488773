export default {
  createQuestion: 'Tạo câu hỏi',
  correctAnswer: 'Đáp án đúng',
  content: 'Nội dung',
  wrongFormat: 'Sai định dạng',
  image: 'Hình ảnh',
  maxImage: ' Tối đa 5 ảnh, định',
  type: 'dạng jpg, png',
  title: 'Hình ảnh hoặc video câu hỏi',
  actionCreateQuestion: 'Bạn đang tạo câu hỏi',
  easy: 'Dễ',
  medium: 'Trung bình',
  hard: 'Khó',
  noMore: 'Không có',
  multipleChoice: 'Trắc nghiệm',
  multipleChoiceHaveCorect: 'Trắc nghiệm có đáp án',
  questionContent: 'Nội dung câu hỏi',
  importNameQuestion: 'Nhập tên câu hỏi',
  catalog: 'Danh mục',
  choose: 'Chọn',
  setOfQuestion: 'Bộ câu hỏi',
  levelOfDifficult: 'Độ khó',
  update: 'Cập nhật',
  moreAnswers: 'THÊM CÂU TRẢ LỜI',
  titleImage: 'Tối đa 1 ảnh, định',
  titleImageOne: 'dạng jpg, png',
  importQuestion: 'Nhập câu trả lời',
  suggest: 'Gợi ý câu trả lời',
};
