export default {
  title: 'Cuộc thi "Tìm hiểu Pháp luật trực tuyến" 2021',
  round: 'Vòng',
  round1: 'Vòng 1',
  round2: 'Vòng 2',
  round3: 'Vòng 3',
  rank: 'Xếp hạng',
  name: 'Tên thí sinh',
  email: 'Email',
  phone: 'Số điện thoại',
  point: 'Số điểm',
  time: 'Thời gian làm bài',
  result: 'Kết quả cuộc thi',
  plName: 'Nhập tên thí sinh',
  search: 'Tìm kiếm',
  nodata: 'Chưa có kết quả',
  donor: 'Nhà tài trợ',
  inforRound: 'Thông tin vòng thi',
  inforCompetiton: 'Thông tin cuộc thi',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  timeCompetition: 'Thời gian cuộc thi',
  numberQues: 'Số lượng câu hỏi',
  easy: 'Số lượng câu hỏi dễ',
  medium: 'Số lượng câu hỏi trung bình',
  difficult: 'Số lượng câu hỏi khó',
  timeWork: 'Thời gian làm bài',
  seconds: 'giây',
  minutes: 'phút',
};
