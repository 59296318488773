export default {
  titleTable: 'Danh sách nội quy',
  addRules: 'Thêm nội quy',
  nameRules: 'Tên nội quy',
  action: 'Hành động',
  showAll: 'Hiển thị tất cả nội quy',
  enterNameRules: 'Nhập tên nội quy',
  addrules: 'Thêm Nội Quy',
  enterContent: 'Nhập nội dung',
  deleteRules: 'Xoá nội quy',
  confirmDelete: 'Bạn có chắc muốn xoá nội quy không?',
  editRules: 'Sửa nội quy',
  titleButtonEdit: 'Sửa',
  labelDis: 'Nhập tên nội quy',
  content: 'Nội dung',
};
