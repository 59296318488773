import {
  getListCatalogContestApi,
  getQuestionContestApi,
  getNotificationAnswerApi,
} from 'services/apis/user/studyfortest';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getListCatalogContest = createAsyncThunk(
  'catalog/GET_CATALOG_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getListCatalogContestApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getQuestionsContest = createAsyncThunk(
  'catalog/GET_QUESTIONS_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getQuestionContestApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);
export const getAnswerQuestion = createAsyncThunk(
  'catalog/GET_ANSWER_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await getNotificationAnswerApi(params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const initialState = {
  listCatalogContest: [],
  listQuestionsContest: {},
  listAnswerQuestion: [],
  loading: false,
  error: {},
};

const userStudyForTestSlice = createSlice({
  name: 'userCatalogContest',
  initialState,

  extraReducers: {
    [getListCatalogContest.pending]: (state) => {
      state.loading = true;
    },
    [getListCatalogContest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getListCatalogContest.fulfilled]: (state, action) => {
      state.listCatalogContest = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
    [getQuestionsContest.pending]: (state) => {
      state.loading = true;
    },
    [getQuestionsContest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getQuestionsContest.fulfilled]: (state, action) => {
      state.listQuestionsContest = action.payload;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
    [getAnswerQuestion.pending]: (state) => {
      state.loading = true;
    },
    [getAnswerQuestion.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    [getAnswerQuestion.fulfilled]: (state, action) => {
      state.listAnswerQuestion = action.payload.data;
      state.paginate = action.payload.paginate;
      state.loading = false;
      state.error = {};
    },
  },
});

const { reducer: userStudyForTestReducer } = userStudyForTestSlice;
export default userStudyForTestReducer;
