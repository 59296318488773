export default {
  titleContentColumOne:
    'Phong & Partners cung cấp tất cả dịch vụ pháp lý với chất lượng cao nhất - là kết quả từ sự chuyên nghiệp, chuyên tâm và sự gắn kết, hỗ trợ hiệu quả của toàn hệ thống vì lợi ích cao nhất của khách hàng.',
  titleColumTwo: 'Văn phòng luật sư Phong & Partners',
  headQuarters: 'Trụ sở chính:',
  branchOne: 'Chi nhánh',
  branchTwo: 'Chi nhánh 2:',
  branchThree: 'Chi nhánh 3:',
  contentHeadQuarters:
    'Tầng 6 Tòa nhà Thành Lợi, 249 Nguyễn Văn Linh, P. Vĩnh Trung, Q. Thanh Khê, TP. Đà Nẵng',
  phoneHeadQuarters: 'Điện thoại: ',
  phoneAddressOne: 'Số điện thoại:',
  phoneAddressTwo: 'Số điện thoại: 0901 955 099',
  phoneAddressThree: 'Số điện thoại: 0905 579 269',
  addressOne: '01 Đông Giang, Sơn Trà, Đà Nẵng',
  addressTwo: '21 Trương Văn Đa, Liên Chiểu,',
  addressThree: '03 Chu Cẩm Phong, Ngũ Hành Sơn, Đà Nẵng',
  titleColumThree: 'Khoa Luật, Trường ĐH Kinh tế - Đại học Đà Nẵng',
  address: 'Địa chỉ:',
  phone: 'Số điện thoại:',
  email: 'Email:',
  webSite: 'Website:',
  addressColumnThree: '71 Ngũ Hành Sơn, Q. Ngũ Hành Sơn, TP. Đà Nẵng',
  phoneColumnThree: '0236 3958418',
  mailColumnThree: 'khoaluat@due.edu.vn',
  webSiteColumnThree: 'due.edu.vn',
  introduce: 'Giới thiệu',
  news: 'Tin tức',
  contact: 'Liên hệ',
  followUs: 'Theo dõi chúng tôi',
  contentFooter: 'Copyright © 2022 P&P. All Rights Reserved.',
  link: 'Liên kết',
};
