export default {
  title: 'cuộc thi',
  sort: 'Sắp xếp theo :',
  news: 'Mới nhất',
  learTheLaw: 'Tìm hiểu Pháp luật trực tuyến 2022',
  roundOne: 'Vòng 1:',
  roundTwo: 'Vòng 2:',
  roundThree: 'Vòng 3:',
  point: 'điểm - Xếp hạng',
  old: 'Cũ nhất',
  informationUser: 'Thông Tin Thí Sinh',
  informationUserName: 'Họ và tên',
  birthday: 'Ngày sinh',
  phone: 'Số điện thoại',
  contestJoin: 'Các cuộc thi đã tham gia',
};
